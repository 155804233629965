/*
Author       : Dreamguys
Template Name: SmartHR - Bootstrap Admin Template
Version      : 3.6
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Animation
6. Toggle Button
7. Modal
8. Header
9. Sidebar
10. Content
11. Login
12. Dashboard
13. Activity
14. Select2
15. Nav tabs
16. Holidays
17. Delete Modal
18. Edit Profile
19. Chat
20. Focus Label
21. Leave
22. Employee
23. Events
24. Profile
25. Notifications
26. Roles & Permissions
27. Chat Right Sidebar
28. Projects
29. Invoice
30. Task
31. Project View
32. Payslip
33. Attendance
34. Ticket
35. Client Profile
36. Inbox
37. Mail View
38. Voice call
39. Video Call
40. Outgoing call
41. Incoming call
42. Contacts
43. Chat Sidebar
44. Jobs
45. Job Details
46. Notification Settings
47. Leave Settings
48. Termination
49. Loader
50. Payroll Items
51. Error
52. Lock Screen
53. OTP
54. Salary Settings
55. Components
56. Search
57. Knowledgebase
58. FAQ
59. Employee Dashboard
60. Performance Review
61. Kanban Board
62. File Manager
63. Subscriptions
64. Responsive

========================================*/

@import 'ngx-toastr/toastr';

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: normal;
	src: url(assets/fonts/MaterialIcons-Regular.eot);
	/* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(assets/fonts/MaterialIcons-Regular.woff) format('woff'),
	url(assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}


/*@font-face { */
	/*     font-family: "Open_Sans", Tofu; */
	/*src: url('assets/fonts/Segoe/71b00e98168f1a2da1b6c5748587e433.eot');*/
/*url('assets/fonts/Segoe/71b00e98168f1a2da1b6c5748587e433.woff')format('woff'),*/
/*url('assets/fonts/Segoe/71b00e98168f1a2da1b6c5748587e433.woff2')format("woff2"),*/
	/* src:  url("assets/fonts/Open_Sans/static/OpenSans_Condensed-Regular.ttf")format("truetype");
	transition-property: opacity, transform;
    font-family: "Open_Sans", Tofu;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-optical-sizing: auto;
    opacity: 1;
    line-height: initial;
    transition-duration: 350ms; */
/*}*/

@font-face {
    font-family: "Open Sans";
    src: url("assets/fonts/Open_Sans/static/OpenSans_Condensed-Regular.ttf") format("truetype");
    font-weight: normal;
	/* width:100; */
    font-style: normal;
    font-display: swap;
}
/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*font-style: normal;*/
	/*font-weight: normal;*/
	/*src:  url('assets/fonts/Inter/Inter-Regular.ttf') format('truetype');*/
/*}*/
/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*src:  url('assets/fonts/Urbanist/Urbanist-Regular.ttf') format('truetype');*/
/*}*/


/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*font-style: normal;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Medium'), url('assets/fonts/CircularStd-Medium.woff') format('woff');*/
/*}*/

/*@font-face {*/
	/*font-family: 'Open_Sans';*/
	/*font-style: italic;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Medium Italic'), url('assets/fonts/CircularStd-MediumItalic.woff') format('woff');*/
/*}*/

/*@font-face {*/
	/*font-family: 'Open_Sans';*/
	/*font-style: normal;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Bold'), url('assets/fonts/CircularStd-Bold.woff') format('woff');*/
/*}*/

/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*font-style: italic;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Bold Italic'), url('assets/fonts/CircularStd-BoldItalic.woff') format('woff');*/
/*}*/

/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*font-style: normal;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Black'), url('assets/fonts/CircularStd-Black.woff') format('woff');*/
/*}*/

/*@font-face {*/
	/*    font-family: "Open_Sans", Tofu;*/
	/*font-style: normal;*/
	/*font-weight: normal;*/
	/*src: local('Circular Std Black Italic'), url('assets/fonts/CircularStd-BlackItalic.woff') format('woff');*/
/*}*/


@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 400;
	src: local('Circular Std Book'), url('assets/fonts/CircularStd-Book.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 400;
	src: local('Circular Std Book Italic'), url('assets/fonts/CircularStd-BookItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 500;
	src: local('Circular Std Medium'), url('assets/fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 500;
	src: local('Circular Std Medium Italic'), url('assets/fonts/CircularStd-MediumItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 600;
	src: local('Circular Std Bold'), url('assets/fonts/CircularStd-Bold.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 600;
	src: local('Circular Std Bold Italic'), url('assets/fonts/CircularStd-BoldItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 800;
	src: local('Circular Std Black'), url('assets/fonts/CircularStd-Black.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 800;
	src: local('Circular Std Black Italic'), url('assets/fonts/CircularStd-BlackItalic.woff') format('woff');
}

/*-----------------
	1. General
-----------------------*/

html {
	height: 100%;
}

body {

	font-family: "Open_Sans", Tofu;
	font-size: 14px;
	font-style: normal;
	color: #1f1f1f;
	font-weight: normal;
	background-color: #FFF;
	line-height: 24px;
	overflow-x: hidden;
	letter-spacing: 0.4px;
	font-stretch: condensed;

}

h1,
h2,
h3,
h4,
h5,
h6 {
	    font-family: "Open_Sans", Tofu;
	margin-top: 0;
	font-weight: normal;
}

h1,
.h1 {
	font-size: 2.25rem;
}

h2,
.h2 {
	font-size: 16px;
}

h3,
.h3 {
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 0.875rem;
}

h6,
.h6 {
	font-size: 0.75rem;
}

a,
a:hover,
a:active,
a:focus {
	text-decoration: none;
	outline: none;
}

a:hover,
a:focus-visible {
	cursor: pointer;
}

.form-control {
	border-color: #e3e3e3;
	box-shadow: none;
	font-size: 14px;
	height: 30px;
}
.grid-table{

	padding-top: 70px;
	margin-top:40px;
	padding-bottom:30px;

}
.form-control:focus {
	border-color: #ccc;
	box-shadow: none;
	outline: 0 none;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;

}

input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
	outline: none;
}

input[type="file"] {
	height: auto;
}

input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

textarea.form-control {
	resize: vertical;
	height: auto;
}

.navbar-nav>li {
	float: left;
}

.form-control-lg.form-control {
	border-radius: 4px;
	height: 46px;
}

.form-control-sm.form-control {
	height: 10px;
	border-radius: 0;
	/* margin-top: 50px; */

}

.input-group.input-group-lg .form-control {
	height: 46px;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 14px;
	display: inherit;
	line-height: 1;
	text-transform: none;
	letter-spacing: 0.4px;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}

.input-group-text {
	background-color: #f7f7f7;
	border-color: #e3e3e3;
}

select.form-control[multiple] option{

	position: relative;
	margin-top: 10px;
	padding: 12px 16px;
}
select.form-control[size] option {
	border-radius: 3px;
	padding: 7px 12px;
}

select.form-control[multiple]{
	padding: 12px 16px;
}
select.form-control[size] {
	height: 200px;
	padding: 7px;
}

.form-group {
	margin-bottom: 1rem;
}

.table>:not(:first-child) {
	border: 0;
}

/*-----------------
	2. Table
-----------------------*/


.table {
	color: #333;
	box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);
}

.table.table-white {
	background-color: #fff;
}

.table thead th {
	font-weight: normal;
	letter-spacing: 0.4px; 	background: #DDDDDD;
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
	background-color: #E2E4E6;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
	background-color: #FFFFFF !important;
	--bs-table-bg-type: #FFFFFF !important;
}.table-striped>tbody>tr:nth-of-type(even)>* {
	background-color: #F7F7F7;
}
table.table-striped.custom-table.datatable.dataTable.no-footer{
	padding-top:33px;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: normal;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}

table.table td h2.table-avatar {
	align-items: center;
	display: inline-flex;
	font-size: inherit;
	font-weight: normal;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	white-space: nowrap;
}

table.table td h2.table-avatar.blue-link a {
	color: #007bff;
}

table.table td h2 a {
	color: #333;
}

table.table td h2 a:hover {
	color: #00c5fb;
}

table.table td h2 span {
	color: #888;
	display: block;
	font-size: 14px;
	margin-top: 3px;
}

table.dataTable {
	margin-bottom: 15px !important;
	margin-top: -15px !important;
	border-collapse: collapse;
	padding-top: 90px;
}

.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}

.table-hover tbody tr:hover {
	background-color: #f7f7f7;
	color: #212529;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
	padding-right: 30px !important;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>tbody>tr>th,
.table.custom-table>tfoot>tr>td,
.table.custom-table>tfoot>tr>th,
.table.custom-table>thead>tr>td,
.table.custom-table>thead>tr>th {
	padding: 10px 20px !important;
}

table.table td,
table.table th {
	border-color: #D9D9D9;

}

.table-bordered td,
.table-bordered th {
	border: 1px solid;
}

.dataTables_length {
	left: 300px;
	position: absolute;


}

.dataTables_filter {
	left: 40px;
	position: absolute;


}

/* table {
	padding-top: 0px;
} */

.dataTables_info {

	position: absolute;
	margin-top: 10px;
}

.pagination {
	position: absolute;
	float: right;
	padding: 20px;
	padding-left: 387px;
}

.dataTables_empty {
	display: none;
}

/*-----------------
	3. Helper Class
-----------------------*/

.p-20 {
	padding: 20px !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-l-5 {
	margin-left: 5px !important;
}

.m-l-15 {
	margin-left: 15px !important;
}

.m-t-5 {
	margin-top: 5px !important;
}

.m-t-0 {
	margin-top: 0 !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-t-50 {
	margin-top: 50px !important;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.w-40 {
	width: 40px;
}

.btn-md {
	height: 40px;
}

.block {
	display: block !important;
}

.text-ellipsis {
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-muted-light {
	color: #aaa;
}

.text-xs {
	font-size: .75rem !important;
}

.text-sm {
	font-size: .875rem !important;
}

.text-lg {
	font-size: 1.25rem !important;
}

.text-xl {
	font-size: 1.5rem !important;
}

.font-18 {
	font-size: 16px;
}

.btn.btn-rounded {
	border-radius: 50px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn {
	border-radius: 5px;
}

.btn.disabled,
.btn:disabled {
	cursor: not-allowed;
}

.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}

.btn-white:focus,
.btn-white:hover {
	background-color: #f7f7f7;
}

.rounded,
.circle {
	border-radius: 500px;
}

.bg-purple,
.badge-purple {
	background-color: #7460ee !important;
}

.bg-primary,
.badge-primary {
	background-color: #00c5fb !important;
}

.bg-success,
.badge-success {
	background-color: #55ce63 !important;
}

.bg-info,
.badge-info {
	background-color: #009efb !important;
}

.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}

.bg-danger,
.badge-danger {
	background-color: #f62d51 !important;
}

.bg-white {
	background-color: #fff;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
	color: #00c5fb !important;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.text-success,
.dropdown-menu>li>a.text-success {
	color: #55ce63 !important;
}

.text-danger,
.dropdown-menu>li>a.text-danger {
	color: #f62d51 !important;
}

.text-info,
.dropdown-menu>li>a.text-info {
	color: #009efb !important;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
	color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
	color: #7460ee !important;
}

.text-muted {
	color: #8e8e8e !important;
}
.p-datatable .p-datatable-tbody  > tr > th {
	color: black;
	background-color: #f1f3f2;

}
.p-datatable .p-datatable-tbody > tr:nth-of-type(even)>* {
	background-color: #F7F7F7;
}
.p-datatable .p-datatable-tbody > tr > td {
	padding: 0.5rem 1rem !important;
}
.p-button.p-button-outlined {
	height:30px;
}

.p-link{
	color: black;
	background-color: #f8f9fa;
}
/*.p-inputnumber-input {*/
	/*min-width: 255px ;*/
	/*max-width: 100%;*/
/*}*/

.btn-purple {
	background-color: #7460ee;
	border: 1px solid #7460ee;
	color: #fff;
}

.btn-purple:hover,
.btn-purple:focus {
	background-color: #482fe9;
	border: 1px solid #482fe9;
}

.btn-primary {
	background-color: #fff;
	border: 1px solid #fff;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5);
color:black;
}.fc .fc-button-primary {
	background-color: #fff;
	border: 1px solid #fff;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5) !important;
color:black;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.fc .fc-button-primary:hover,
.fc .fc-button-primary:focus,
.fc .fc-button-primary.active,
.fc .fc-button-primary.disabled,
.add-btn:hover, .add-btn:focus, .add-btn:active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
	background-color: #00b7ed;
	border: 1px solid #00b7ed;
	color:#FFFFFF;
}


.add-btn:hover, .add-btn:focus, .add-btn:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
	background-color: #00b7ed;
	border: 1px solid #00b7ed;
	color:#FFFFFF;

}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
	background-color: #00b7ed;
	border-color: #00b7ed;
	color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #00c5fb;
	border-color: #00c5fb;
	color: #fff;
}

.btn-success {
	background-color: #55ce63;
	border: 1px solid #55ce63
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	background: #4ab657;
	border: 1px solid #4ab657;
	color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
	background: #4ab657;
	border: 1px solid #4ab657
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: #55ce63;
	border-color: #55ce63;
	color: #fff;
}

.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
	background-color: #009efb;
	border-color: #009efb;
	color: #fff;
}

.btn-warning {
	background: #ffbc34;
	border: 1px solid #ffbc34
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffbc34;
	border-color: #ffbc34;
	color: #fff;
}

.btn-danger {
	background: #f62d51;
	border: 1px solid #f62d51
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
	background-color: #e6294b;
	border: 1px solid #e6294b
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}

.btn-custom {
	background: #00c5fb;
	background: -moz-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -webkit-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -ms-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
	color: #fff;
}

.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
	background: #00c5fb;
	background: -moz-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -webkit-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -ms-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
	color: #fff;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: #00c5fb;
	border-color: #00c5fb;
}

.pagination>li>a,
.pagination>li>span {
	color: #00c5fb;
}

.page-link:hover {
	color: #00c5fb;
}

.page-link:focus {
	box-shadow: unset;
}

.page-item.active .page-link {
	background-color: #00c5fb;
	border-color: #00c5fb;
}

.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
	font-size: 14px;
	top: 0px;
	bottom: 80px;


}
.dropdown-menu .show{
	z-index: 2;
}

.navbar-nav .open .dropdown-menu {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);

}


.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	box-shadow: none;
}

.btn-link {
	color: #333;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.form-text {
	color: #999;
	font-size: 14px;
	margin-bottom: 7px;
	margin-top: 7px;
}

.btn.focus,
.btn:focus {
	box-shadow: unset;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #00c5fb;
}

.list-group-item {
	border: 1px solid #e3e3e3;
}

.custom-file {
	height: 44px;
}

.custom-file .custom-file-label {
	border: 1px solid #e3e3e3;
	height: 44px;
	margin-bottom: 0;
	padding: 10px 15px;
}

.custom-file-label::after {
	background-color: #f2f2f2;
	height: 42px;
	padding: 10px 15px;
}

.custom-file input[type="file"] {
	height: 44px;
}

.bg-inverse-primary {
	background: rgba(77, 138, 240, .2) !important;
	color: #4d8af0 !important;
}

.bg-inverse-secondary {
	background: rgba(194, 198, 209, .2) !important;
	color: #c2c6d1 !important;
}

.bg-inverse-success {
	background-color: rgba(15, 183, 107, 0.12) !important;
	color: #26af48 !important;
}

.bg-inverse-purple {
	background: rgba(108, 97, 246, .2) !important;
	color: #6c61f6 !important;
}

.bg-inverse-warning {
	background-color: rgba(255, 152, 0, 0.12) !important;
	color: #f39c12 !important;
}

.bg-inverse-danger {
	background-color: rgba(242, 17, 54, 0.12) !important;
	color: #e63c3c !important;
}

.bg-inverse-light {
	background: rgba(223, 223, 223, .2) !important;
	color: #dfdfdf !important;
}

.bg-inverse-dark {
	background: rgba(116, 118, 123, .2) !important;
	color: #74767b !important;
}

.bg-inverse-white {
	background: rgba(255, 255, 255, .2) !important;
	color: #fff !important;
}

.bg-inverse-info {
	background-color: rgba(2, 182, 179, 0.12) !important;
	color: #1db9aa !important;
}

.bg-inverse-default {
	background-color: rgba(40, 52, 71, 0.12) !important;
	color: #283447 !important;
}

.bg-gradient-primary {
	background: linear-gradient(to right, #6e00ff 10%, #6e00ff 100%);
}

.bg-gradient-secondary {
	background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}

.bg-gradient-success {
	background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}

.bg-gradient-info {
	background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}

.bg-gradient-warning {
	background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%)
}

.bg-gradient-teal {
	background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}

.bg-gradient-blue {
	background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-gradient-danger {
	background: linear-gradient(to right, #f95058 0, #f33540 100%);
}

.bg-gradient-purple {
	background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}

/*-----------------
	5. Animation
-----------------------*/

@-moz-keyframes pulse {
	0% {
		-moz-transform: scale(0);
		opacity: 0.0;
	}

	25% {
		-moz-transform: scale(0);
		opacity: 0.1;
	}

	50% {
		-moz-transform: scale(0.1);
		opacity: 0.3;
	}

	75% {
		-moz-transform: scale(0.5);
		opacity: 0.5;
	}

	100% {
		-moz-transform: scale(1);
		opacity: 0.0;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;
	}

	25% {
		-webkit-transform: scale(0);
		opacity: 0.1;
	}

	50% {
		-webkit-transform: scale(0.1);
		opacity: 0.3;
	}

	75% {
		-webkit-transform: scale(0.5);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1);
		opacity: 0.0;
	}
}

@-webkit-keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}

	100% {
		background: #fff;
	}
}

@keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}

	100% {
		background: #fff;
	}
}

/*-----------------
	6. Toggle Button
-----------------------*/

.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}

.checktoggle {
	background-color: #e0001a;
	border-radius: 12px;
	cursor: pointer;
	display: block;
	font-size: 14px;
	height: 24px;
	margin-bottom: 0;
	position: relative;
	width: 48px;
}

.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
	background-color: #55ce63;
}

.check:checked+.checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
	margin-left: auto;
	position: relative;
	width: 73px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}

.onoffswitch-inner {
	display: block;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
	width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
	box-sizing: border-box;
	color: #fff;
	display: block;
	float: left;
	font-size: 14px;
	height: 30px;
	line-height: 32px;
	padding: 0;
	width: 50%;
}

.onoffswitch-inner:before {
	background-color: #55ce63;
	color: #fff;
	content: "ON";
	padding-left: 14px;
}

.onoffswitch-inner:after {
	content: "OFF";
	padding-right: 14px;
	background-color: #ccc;
	color: #fff;
	text-align: right;
}

.onoffswitch-switch {
	background: #fff;
	border-radius: 20px;
	bottom: 0;
	display: block;
	height: 20px;
	margin: 5px;
	position: absolute;
	right: 43px;
	top: 0;
	transition: all 0.3s ease-in 0s;
	width: 20px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
	right: 0px;
}

.switch {
	cursor: pointer;
	position: relative;
}

.switch input {
	position: absolute;
	opacity: 0;
	filter: alpha(opacity=0);
}

.switch input:checked+span {
	background-color: #55ce63;
}

.switch input:checked+span:after {
	left: 31px;
}

.switch span {
	position: relative;
	width: 60px;
	height: 30px;
	border-radius: 30px;
	background-color: #fff;
	border: 1px solid #eee;
	border-color: rgba(0, 0, 0, 0.1);
	display: inline-block;
	-webkit-transition: background-color 0.2s;
	transition: background-color 0.2s;
}

.switch span:after {
	content: "";
	position: absolute;
	background-color: #fff;
	width: 26px;
	top: 1px;
	bottom: 1px;
	border-radius: 30px;
	-webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
	-webkit-transition: left 0.2s;
	transition: left 0.2s;
}

.rating-list {
	display: flex;
	justify-content: center
}

.rating-list>span {
	display: inline-block;
	margin-right: 4px;
}

.rating-list span a {
	background-color: #ccc;
	border-radius: 4px;
	color: #333;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 5px;
	margin-left: 1px;
	margin-right: 1px;
}

.rating-list span.rating-bad a {
	background-color: #ad0d20;
	color: #fff;
}

.rating-list span.rating-normal a {
	background-color: #dccc00;
	color: #fff;
}

.rating-list span.rating-good a {
	background-color: #00a100;
	color: #fff;
}

.rating-text {
	font-size: 14px;
	max-width: 157px;
	display: inline-block;
	margin-top: 5px;
}

.rating-list>span.rating-normal {
	text-align: center;
}

.rating-list>span.rating-good {
	text-align: right;
}

.custom_check {
	color: #555;
	display: inline-block;
	position: relative;
	font-size: 14px;
	/*font-size: .9375rem;*/
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}

.custom_check input:checked~.checkmark {
	background-color: #ff9b44;
	border: 1px solid transparent
}

.custom_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #d2d8dd;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}

.custom_check .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg)
}

.custom_check input:checked~.checkmark:after {
	display: block
}

.custom_radio {
	color: #555;
	display: inline-block;
	position: relative;
	font-size: 14px;
	/*font-size: 0.9375rem;*/
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.radio_input .custom_radio+.custom_radio {
	margin-left: 15px;
}

.custom_radio input {
	position: absolute;
	opacity: 0
}

.custom_radio input:checked~.checkmark:after {
	opacity: 1
}

.custom_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%
}

.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #ff9b44;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}

.setting-performance-table.table>tbody>tr>td,
.setting-performance-table.table>tbody>tr>th,
.setting-performance-table.table>tfoot>tr>td,
.setting-performance-table.table>tfoot>tr>th,
.setting-performance-table.table>thead>tr>td,
.setting-performance-table.table>thead>tr>th {
	vertical-align: middle;
}

.setting-performance-table textarea.form-control {
	resize: none;
	height: 44px !important;
	padding-top: 10px !important;
}

/*-----------------
	7. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}

.modal-footer.text-center {
	justify-content: center;
}

.modal-footer.text-left {
	justify-content: flex-start;
}

.modal-dialog.modal-md {
	max-width: 600px;
}

.custom-modal .modal-content {
	border: 0;
	border-radius: 10px;

}

.custom-modal .modal-footer {
	border: 0;
}

.custom-modal .modal-header {
	border: 0;
	justify-content: center;
	padding: 30px 30px 0;
}

.custom-modal .modal-footer {
	border: 0;
	justify-content: center;
	padding: 0 30px 30px;
}

.custom-modal .modal-body {
	padding: 30px;
}

.custom-modal .close {
	background-color: #a0a0a0;
	border: 0;
	border-radius: 50%;
	color: #fff;
	font-size: 14px;
	height: 20px;
	line-height: 20px;
	margin: 0;
	opacity: 1;
	padding: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	z-index: 99;
}

.custom-modal .close:not(:disabled):not(.disabled):focus,
.custom-modal .close:not(:disabled):not(.disabled):hover {
	opacity: .75;
}

.custom-modal .modal-title {
	font-size: 16px;
}

.modal-backdrop.show {
	opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}

.modal .card {
	box-shadow: unset;
}

/*-----------------
	8. Header
-----------------------*/

.header {
	/* background: #00A3D1;
	background: -moz-linear-gradient(left, #00A3D1 5.31%, #0074C7 102.42%);
	background: -webkit-linear-gradient(left, #00A3D1 5.31%, #0074C7 102.42%);
	background: -ms-linear-gradient(left, #00A3D1 5.31%, #0074C7 102.42%);
	background: linear-gradient(to right, #00A3D1 5.31%, #0074C7 102.42%); */
  color: #fff;
  background-color: #3e75b3;
	border-bottom: 1px solid transparent;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	height: 60px;
	position: fixed;
	top: 0;
	width: -webkit-fill-available;
	z-index: 1002;
}

.header .header-left {
	float: left;
	height: 40px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	width: 210px;
	z-index: 1;
	background-color: #01265E;
	transition: all 0.2s ease-in-out;
}

.header .header-left .logo {
	display: inline-block;
	line-height: 40px;
}

.bar-icon {
	display: inline-block;
	width: 21px;
}

.bar-icon span {
	background-color: #fff;
	border-radius: 500px;
	display: block;
	float: left;
	height: 2px;
	margin-bottom: 5px;
	width: 21px;
}

.bar-icon span:nth-child(2) {
	width: 15px;
}

.bar-icon span:nth-child(3) {
	margin-bottom: 0;
}

.header .navbar-nav .badge {
	position: absolute;
	right: 7px;
	top: 4px;
}

.header .dropdown-menu>li>a {
	position: relative;
}

.header .dropdown-toggle::after {
	display: none;
}

.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.page-title-box {
	border-radius: 0;
	float: left;
	height: 60px;
	margin-bottom: 0;
	padding: 17px 20px;
}

.page-title-box h3 {
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	margin: 0;
}

.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
}

.user-menu.nav>li>a {
	color: #fff;
	font-size: 14px;
	padding: 0 15px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

.flag-nav img {
	margin-right: 5px;
}

.flag-nav .dropdown-menu {
	max-height: 150px;
	min-width: 120px;
	overflow-y: auto;
}

.flag-nav .dropdown-menu .dropdown-item {
	padding: 5px 15px;
}

.user-img {
	display: inline-block;
	position: relative;
	margin-right: 5px;
}

.main-drop .user-img img {
	border-radius: 50%;
	width: 30px;
}

.user-img .status {
	border: 2px solid #fff;
	bottom: 0;
	height: 10px;
	margin: 0;
	position: absolute;
	right: 0;
	width: 10px;
}

.user-menu .user-img .status {
	bottom: 12px;
}

.status {
	background-color: #263238;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	width: 10px;
}

.status.online {
	background-color: #55ce63;
}

.status.offline {
	background-color: #f62d51;
}

.status.away {
	background-color: #faa937;
}

.main-drop .dropdown-menu {
	min-width: 130px;
	padding: 0;
}

/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {
	background-color: #FFF;
	border-right: 1px solid transparent;
	bottom: 0;
	left: 0;
	margin-top: 0;
	position: fixed;
	top: 40px;
	transition: all 0.2s ease-in-out 0s;
	width: 0px;
	z-index: 1001;
	color:black !important;
}

.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.sidebar-inner {
	height: 100%;
	transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
	padding: 10px 0;
}

.sidebar-menu ul {
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	padding: 0;
	position: relative;
}

.sidebar-menu li a {
	color: black;
	display: block;
	font-size: 14px;
	height: auto;
	padding: 0 20px;
}

.sidebar-menu li a:hover {
	color: blue;
}

.sidebar-menu li.active a {
	color: #fff;

}

.menu-title {
	color: #ebecf1;
	display: flex;
	font-size: 14px;
	opacity: 1;
	padding: 5px 15px;
	white-space: nowrap;
}

.menu-title>i {
	float: right;
	line-height: 40px;
}

.sidebar-menu li.menu-title a {
	color: #00c5fb;
	display: inline-block;
	margin-left: auto;
	padding: 0;
}

.sidebar-menu li.menu-title a.btn {
	color: #fff;
	display: block;
	float: none;
	font-size: 14px;
	margin-bottom: 15px;
	padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
	color: #00c5fb;
	text-decoration: underline;
}

.mobile-user-menu {
	color: #fff;
	display: none;
	float: right;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
	padding: 0 20px;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
	width: 60px;
	z-index: 10;
}

.mobile-user-menu>a {
	color: #fff;
	padding: 0;
}

.mobile-user-menu a:hover {
	color: #fff;
}

.mobile-user-menu>.dropdown-menu {
	min-width: 130px;
}

.mobile-user-menu>.dropdown-menu>a {
	line-height: 23px;
}

.profile-rightbar {
	display: none !important;
	color: #bbb;
	font-size: 16px;
	margin-left: 15px;
}

.mobile_btn {
	display: none;
	float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 15px;
	white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
	margin-left: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
	margin-left: auto;
}

.sidebar-menu ul ul a {
	display: block;
	font-size: 14px;
	padding: 9px 10px 9px 50px;
	position: relative;
}

.sidebar-menu ul ul {
	margin-bottom: 5px;
    margin-top: -5px;
    display: none;
    background: ghostwhite;
}

.sidebar-menu ul ul ul a {
	padding-left: 70px;
}

.sidebar-menu ul ul ul ul a {
	padding-left: 90px;
}

.sidebar-menu>ul>li {
	position: relative;
}

.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 10px;
	display: inline-block;
	    font-family: "Open_Sans", Tofu;
	text-rendering: auto;
	line-height: 40px;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}

.sidebar-menu .menu-arrow:before {
	/*!*content: "\f105";*!*/
	/*background-image: url(assetsmenu-dropdown.png);*/
}

.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.noti-dot:before {
	content: '';
	width: 5px;
	height: 5px;
	border: 5px solid #00c5fb;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: #00c5fb;
	z-index: 10;
	position: absolute;
	right: 37px;
	top: 15px;
}

.noti-dot:after {
	content: '';
	border: 4px solid #00c5fb;
	background: transparent;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	height: 24px;
	width: 24px;
	-webkit-animation: pulse 3s ease-out;
	-moz-animation: pulse 3s ease-out;
	animation: pulse 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	position: absolute;
	top: 8px;
	right: 30px;
	z-index: 1;
	opacity: 0;
}

.sidebar-menu ul ul a .menu-arrow {
	top: 6px;
}

.sidebar-menu a {
	line-height: 1.3;
	transition: unset;
	-moz-transition: unset;
	-o-transition: unset;
	-ms-transition: unset;
	-webkit-transition: unset;
}

.sidebar-menu>ul>li>a {
    padding: 8px 0px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
    border: 1px solid gainsboro;
    margin: 0px 2px 5px -2px;
}

.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
	padding-left: 5px;

}

.sidebar-menu ul li.menu-title a i {
	font-size: 12px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}

.sidebar-menu li a>.badge {
	color: #fff;
}

/*-----------------
	10. Content
-----------------------*/

.page-wrapper {
	left: 0;
	margin-left: 200px;
	padding-top: 35px;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.page-wrapper>.content {
	/*padding: 30px;*/
	padding:0px 20px;
}

.page-header {
	margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
	background-color: transparent;
	color: #6c757d;
	font-size: 1rem;
	font-weight: normal;
	margin-bottom: 0;
	padding: 0;
}

.page-header .breadcrumb a {
	color: #333;
}

.card {
	border: 1px solid #ededed;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
}

.card-title {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

.card-header {
	background-color: #fff;
}

.card-footer {
	background-color: #fff;
}

.page-title {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 5px;
}

.page-sub-title {
	color: #565656;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

/*.add-btn {*/
	/*background-color: #00c5fb;*/
	/*border: 1px solid #00c5fb;*/
	/*border-radius: 50px;*/
	/*color: #fff ;*/
	/*float: right;*/
	/*font-weight: normal;*/
	/*min-width: 110px;*/
/*}*/

.add-btn:hover,
.add-btn:focus,
.add-btn:active {
	background-color: #00b7ed;
	border: 1px solid #00b7ed;
	color: #fff !important;
}

.add-btn i {
	margin-right: 5px;
}

.cal-icon {
	position: relative;
	width: 100%;
}

.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
	    font-family: "Open_Sans", Tofu;
	font-size: 14px;
	margin: auto;
	position: absolute;
	right: 15px;
	top: 10px;
}

.form-focus .cal-icon:after {
	top: 15px;
}

/*-----------------
	11. Login
-----------------------*/

.account-content {
	padding-bottom: 0%;
	padding-bottom: 1%;
	justify-content: right;
	width: 1
}
.login-icon{
  margin-left: 168px;
  margin-top: -20px;
}
/* .login-header{
  float:right;
  padding-right: 30%;
  padding-top: 10%;
  width:fit-content;
} */
.login-header {
  /* float: right; */
  padding: 10%;
  width: -moz-fit-content;
  /* width: fit-content; */
}

.account-title {
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 5px;
	text-align: center;
}

.account-subtitle {
	color: #4c4c4c;
	font-size: 16px;
	margin-bottom: 30px;
	text-align: center;
}

.account-box {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
  box-shadow:6px 5px 11px rgba(45, 46, 51, 0.56), inset 0.2em 0.2em 0.1em #e9e9e9, inset -0.2em -0.2em 0.2em #e9e9e9;
  margin: 0 auto;
	overflow: hidden;
	width: 485px;
}

.account-wrapper {
	padding: 40px;

}

.account-logo img {
	padding-left: 100px;
	padding-top: 100px;
	margin-bottom: 30px;
	text-align: left;
}

.account-box .form-group {
	margin-bottom: 25px;
}

.account-box .account-btn {
	background: #00c5fb;
	background: -moz-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -webkit-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -ms-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
	border: 0;
	border-radius: 4px;
	display: block;
	font-size: 16px;
	padding: 10px 26px;
	width: 100%;
  color: white;
}

.account-box .account-btn:hover,
.account-box .account-btn:focus {
	border: 0;
	opacity: 0.8;
  color: black;
}

.account-box .form-control {
	background-color: #fbfbfb;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	height: px;
}

.account-box label {
	color: #1f1f1f;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 5px;
}

.account-logo img {
	width: 500px;
	float: left;
}

.account-footer {
	text-align: center;
}

.account-footer p {
	margin-bottom: 0;
}

.account-footer a {
	color: #00c5fb;
}

.account-footer a:hover {
	color: #00c5fb;
}

.account-box .form-control.is-invalid,
.account-box .was-validated .form-control:invalid {
	border-color: rgb(220, 53, 69);
}

.toggle-password {
	cursor: pointer;
	margin-right: 1px;
	position: absolute;
	right: 10px;
	top: 15px;
}

.is-invalid+.toggle-password {
	display: none;
}

.mandatory {
	margin-left: 5px;
	color: red;
}

/*-----------------
	12. Dashboard
-----------------------*/

.card-table .card-header {
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	padding: 15px;
}

.card-table .card-body {
	padding: 0;
}

.card-table .card-footer {
	background-color: #fff;
	text-align: center;
}

.card-table .card-footer a {
	color: #333;
}

.card-title>a.btn {
	color: #fff;
}

.custom-table tr {
	background-color: #fff;
	box-shadow: 0 0 3px #e5e5e5;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>tbody>tr>th,
.table.custom-table>tfoot>tr>td,
.table.custom-table>tfoot>tr>th,
.table.custom-table>thead>tr>td,
.table.custom-table>thead>tr>th {
	padding: 10px 8px;

}

/* .table.custom-table>tbody>tr>td:nth-child(-n+2) {
	position: relative;
	left: 0px;
	background-color: white;
} */

/********first-child**********/

.table.custom-table>tbody>tr>td:first-child {
	position: sticky;
	left: 0px;
	background-color: white;
}

.table.custom-table>thead>tr>th:first-child {
	position: sticky;
	left: 0px;
}

.table.custom-table>tbody>tr>td:nth-child(2) {
	position: sticky;
	left: 165px;
	background-color: white;
	border-right: 1px solid #ddd;

}

.table.custom-table>thead>tr>th:nth-child(2) {
	position: sticky;
	left: 165px;
	border-right: 1px solid #ddd;

}

.table.custom-table>thead>tr>th:nth-child(n+3) {
	position: static;
}

/* -35px 25px 30px -84px */

/********last-child**********/

.table.custom-table>tbody>tr>td:last-child {
	position: sticky;
	right: 0px;
	background-color: white;
}

.table.custom-table>thead>tr>th:last-child {
	position: sticky;
	right: 0px;
}

.table.custom-table>tbody>tr>td:nth-last-child(2) {
	position: sticky;
	right: 63px;
	background-color: white;
	border-left: 1px solid #ddd;
}

.table.custom-table>thead>tr>th:nth-last-child(2) {
	position: sticky;
	right: 63px;
	border-left: 1px solid #ddd;
}
/* .table.td{
	z-index: 2;
	position: absolute;
} */
/* #datatable_wrapper .dropdown-menu {
	margin-top: -30px !important;
	margin-right: 30px !important;
} */

/* .table.custom-table>tbody>tr>td:nth-last-child(-n+2) {
	background-color: white;
	right: 0px;
	position: absolute;
	margin-right: 132px;
	border-left: 1px solid #ddd;
}

.table.custom-table>tbody>tr>td:last-child {
	background-color: white;
	 right: 0px;
	position: absolute;
	margin-right: 32px;
	border-left: 1px solid #ddd;
	width: 60px;
}



 .table.custom-table>thead>tr>th:nth-last-child(-n+2) {
	 right: 0px;
	position: absolute;
	margin-right: 140px;
	border-left: 1px solid #ddd;
} */

/* .table.custom-table>thead>tr>th:last-child {
	right: 0px;
	position: absolute;
	margin-right: 30px;
	border-left: 1px solid #ddd;
}  */

/* .table.custom-table>thead>tr>th:nth-last-child(2) {
	position: absolute;
	right: 0px;
	margin-right: 130px;
	width: 80px;
}  */

/* .table.custom-table>tbody>tr>td:last-child {
	background-color: white;
	position: sticky;
	border-left: 1px solid #ddd;
} */

.dash-widget-icon {
	background-color: rgba(0, 197, 251, 0.2);
	border-radius: 100%;
	color: #00c5fb;
	display: inline-block;
	float: left;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
	margin-right: 10px;
	text-align: center;
	width: 60px;
}

.dash-widget-info {
	text-align: right;
}

.dash-widget-info>h3 {
	font-size: 16px;
	font-weight: normal;
}

.dash-widget-info>span {
	font-size: 14px;
}

.dash-statistics .stats-info {
	margin-bottom: 5px;
}

.dash-statistics .stats-info:last-child {
	margin-bottom: 0;
}

.dash-statistics .progress {
	height: 4px;
}

.dash-statistics .stats-list {
	height: auto;
}

.leave-info-box {
	border: 1px solid #e5e5e5;
	padding: 15px;
	margin-bottom: 15px;
}

.leave-info-box:last-child {
	margin-bottom: 0;
}

.load-more a {
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 3px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	display: inline-block;
	font-size: 14px;
	padding: 5px 15px;
}

/*-----------------
	13. Activity
-----------------------*/

.activity-box {
	position: relative;
}

.activity-list {
	list-style: none;
	margin: 0 0 0 10px;
	padding: 0;
	position: relative;
}

.activity .activity-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.activity .activity-list>li .activity-user {
	height: 32px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 12px;
	left: 8px;
	width: 32px;
}

.activity .activity-list>li .activity-content {
	background-color: #fff;
	margin: 0 0 0 40px;
	padding: 0;
	position: relative;
}

.activity-list::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 23px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.activity .activity-list li::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 22px;
	position: absolute;
	top: 12px;
	width: 2px;
}

.activity-list li::before {
	background: #eee;
	bottom: 0;
	content: "";
	left: 8px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.activity-list>li {
	background-color: #fff;
	margin-bottom: 10px;
	padding: 10px;
	position: relative;
	border: 1px solid #ededed;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.activity-list>li:last-child .activity-content {
	margin-bottom: 0;
}

.activity-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}

.activity-list>li .activity-user {
	background: #fff;
	height: 32px;
	left: -7px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 3px;
	width: 32px;
}

.activity-list>li .activity-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}

.activity-list>li .activity-content .timeline-content {
	color: #9e9e9e;
}

.activity-list>li .activity-content .timeline-content a {
	color: #616161;
}

.activity-list>li .time {
	color: #bdbdbd;
	display: block;
	font-size: 14px;
}

/*-----------------
	14. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	border-radius: 0.25rem;
	height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 42px;
	right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 42px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #00c5fb;
}

.select2-dropdown {
	z-index: 1055;
	padding: 12px 16px;
}

/*-----------------
	15. Nav Tabs
-----------------------*/

.nav-tabs .nav-link {
	border-radius: 0;
}

.nav-tabs>li>a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	background-color: #eee;
	border-color: transparent;
	color: #333;
}

.nav-tabs.nav-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
	border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
	color: #333;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
	background-color: #00c5fb;
	border-color: #00c5fb;
	color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
	border-radius: 50px;
}

.nav-tabs-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
	border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

.tab-content {
	padding-top: 20px;
}

/*-----------------
	16. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
	color: #aaa;
}

.dropdown.action-label {
	display: inline-block;
}

.action-label>a {
	display: inline-block;
	min-width: 103px;
}

.action-label .btn-sm {
	padding: 4px;
}

/*-----------------
	17. Delete Modal
-----------------------*/

.form-header {
	text-align: center;
	margin-bottom: 30px;
}

.form-header h3 {
	color: #333;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 10px;
}

.form-header p {
	color: #929292;
	font-size: 14px;
	margin-bottom: 0;
}

.modal-btn>.row {
	margin-left: -8px;
	margin-right: -8px;
}

.modal-btn>.row>div {
	padding-left: 8px;
	padding-right: 8px;
}

.continue-btn {
	background-color: #fff;
	border: 1px solid #3e75b3;
	border-radius: 50px;
	color: #3e75b3;
	display: block;
	font-weight: normal;
	padding: 5px 20px;
	text-align: center;
	font-size: 14px;
	/*min-width: inherit;*/
	/*max-width: 100px;*/
	float: right;
	width:100px;
}
.nav-item-width{
	width: 45%;
}
.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
	background-color: #3e75b3;
	border: 1px solid #3e75b3;
	color: #fff;
}

.cancel-btn {
	background-color: #fff;
	border: 1px solid var(--bs-red);
	border-radius: 50px;
	color: var(--bs-red);
	display: block;
	font-weight: normal;
	padding: 5px 20px;
	text-align: center;
	font-size: 14px;
	width:100px;
}

.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
	background-color: var(--bs-red);
	border: 1px solid var(--bs-red);
	color: #fff;
}

/*-----------------
	18. Edit Profile
-----------------------*/

.profile-img-wrap {
	height: 120px;
	position: absolute;
	width: 120px;
	background: #fff;
	overflow: hidden;
}

.profile-basic {
	margin-left: 140px;
}

.profile-basic .cal-icon {
	width: 100%;
}

.profile-img-wrap img {
	border-radius: 50%;
	height: 120px;
	width: 120px;
}

.fileupload.btn {
	position: absolute;
	right: 0;
	bottom: 0;
	background: rgba(33, 33, 33, 0.5);
	border-radius: 0;
	padding: 3px 10px;
	border: none;
}

.fileupload input.upload {
	cursor: pointer;
	filter: alpha(opacity=0);
	font-size: 16px;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
	right: -3px;
	top: -3px;
	padding: 5px;
}

.btn-text {
	color: #fff;
}

.submit-section {
	text-align: center;
	margin-top: 40px;
}

.submit-btn {
	/* border-radius: 50px; */
	font-size: 14px;
	font-weight: normal;
	width: 100px !important;
	/* padding: 10px 20px; */
	color: black;
}

.form-scroll {
	max-height: 400px;
	overflow-y: auto;
}

.profile-img-wrap.edit-img {
	border-radius: 50%;
	margin: 0 auto 30px;
	position: relative;
}

.profile-img-wrap.edit-img .fileupload.btn {
	left: 0;
}

/*-----------------
	19. Chat
-----------------------*/

.chat-avatar-sm {
	display: inline-block;
	float: left;
	margin-left: 0 !important;
	margin-right: 10px;
	position: relative;
	width: 24px;
}

.chat-avatar-sm img {
	width: 24px;
}

.chat-avatar-sm.user-img .status {
	bottom: 0;
	right: -3px;
}

.chat-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}

.chat-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}

.message-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}

.chat-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #f7f7f7;
}

.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #eaeaea;
	padding: 10px 15px;
	margin-top: 60px;
}

.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}

.fixed-header .user-info a {
	color: #555;
	text-transform: uppercase;
}

.typing-text {
	color: #00c5fb;
	font-size: 14px;
	text-transform: lowercase;
}

.last-seen {
	color: #888;
	display: block;
	font-size: 14px;
}

.custom-menu {
	margin-top: 6px;
}

.fixed-header .custom-menu {
	margin: 0 0 1px;
}

.custom-menu.nav>li>a {
	color: #bbb;
	font-size: 16px;
	line-height: 32px;
	margin-left: 15px;
	padding: 0;
}

.custom-menu.navbar-nav>li>a:hover,
.custom-menu.navbar-nav>li>a:focus {
	background-color: transparent;
}

.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}

.search-box {
	margin-top: 5px;
}

.search-box .input-group {
	background-color: #f7f7f7;
	border: 1px solid #e3e3e3;
	border-radius: 50px;
}

.search-box .input-group .form-control {
	background: transparent;
	border: none;
	height: calc(1.5em + .5rem + 2px);
}



.search-box .btn {
	background-color: transparent;
}

.search-input {
	border-radius: 5px;
}

.chat-contents {
	display: table-row;
	height: 100%;
}

.chat-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}

.chat-wrap-inner {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.chats {
	padding: 30px 15px;
}

.chat-body {
	display: block;
	margin: 10px 0 0;
	overflow: hidden;
}

.chat-body:first-child {
	margin-top: 0
}

.chat-right .chat-content {
	background-color: #f2f2f2;
	border: 1px solid #e3e3e3;
	border-radius: 20px 2px 2px 20px;
	clear: both;
	color: #727272;
	display: block;
	float: right;
	margin: 0;
	max-width: 60%;
	padding: 8px 15px;
	position: relative;
}

.chat-right .chat-body {
	padding-left: 48px;
	padding-right: 10px;
}

.chat-bubble {
	display: block;
	width: 100%;
	float: left;
	margin-bottom: 10px;
}

.chat-bubble:hover .chat-action-btns {
	display: block;
	float: left;
}

.chat-right .chat-bubble:hover .chat-action-btns {
	float: right;
	display: block;
}

.chat.chat-right .chat-bubble:last-child .chat-content {
	border-bottom-right-radius: 20px;
}

.chat.chat-right .chat-bubble:first-child .chat-content {
	border-top-right-radius: 20px;
}

.chat-content>p {
	margin-bottom: 0;
}

.chat-time {
	color: rgba(0, 0, 0, 0.5);
	display: block;
	font-size: 14px;
}

.chat-right .chat-time {
	text-align: right;
}

.chat-bubble .chat-action-btns {
	display: none;
}

.chat-action-btns {
	float: right;
}

.chat-action-btns ul {
	list-style: none;
	padding: 0;
	margin: 15px 15px 0 15px;
}

.chat-action-btns ul>li {
	display: inline-block;
	margin-left: 5px;
	font-size: 16px;
}

.chat-right .chat-action-btns {
	float: left;
}

.chat-bubble .chat-action-btns a {
	color: #8c8c8c;
}

.chat-line {
	border-bottom: 1px solid #eaeaea;
	height: 12px;
	margin: 7px 0 20px;
	position: relative;
	text-align: center;
}

.chat-date {
	background-color: #f7f7f7;
	color: #727272;
	font-size: 14px;
	padding: 0 11px;
}

.chat-avatar {
	float: right;
}

.chat-avatar .avatar {
	line-height: 30px;
	height: 30px;
	width: 30px;
}

.chat-left .chat-avatar {
	float: left
}

.chat-left .chat-body {
	margin-right: 0;
	margin-left: 30px;
	padding-right: 20px;
}

.chat-left .chat-content {
	background-color: #fff;
	border: 1px solid #eaeaea;
	color: #888;
	float: left;
	position: relative;
	padding: 8px 15px;
	border-radius: 2px 20px 20px 2px;
	max-width: 60%;
}

.avatar {
	background-color: #aaa;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-weight: normal;
	height: 38px;
	line-height: 38px;
	margin: 0 10px 0 0;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	width: 38px;
	position: relative;
	white-space: nowrap;
}

.avatar:hover {
	color: #fff;
}

.avatar.avatar-xs {
	width: 24px;
	height: 24px
}

.avatar>img {
	border-radius: 50%;
	display: block;
	overflow: hidden;
	width: 100%;
}

.chat.chat-left .chat-bubble:first-child .chat-content {
	border-top-left-radius: 20px;
	margin-top: 0;
}

.chat.chat-left .chat-bubble:last-child .chat-content {
	border-bottom-left-radius: 20px;
}

.chat-left .chat-time {
	color: #a9a9a9
}

.attach-list {
	color: #a3a3a3;
	padding: 5px 0 0;
	line-height: 24px;
	margin: 0;
	list-style: none;
}

.attach-list i {
	margin-right: 3px;
	font-size: 14px;
}

.chat-footer {
	background-color: #fff;
	border-top: 1px solid #eaeaea;
	padding: 15px;
}

.message-bar {
	display: table;
	height: 44px;
	position: relative;
	width: 100%;
}

.message-bar .message-inner {
	display: table-row;
	height: 100%;
	padding: 0 8px;
	width: 100%;
}

.message-bar .link {
	color: #777;
	display: table-cell;
	font-size: 16px;
	padding: 0 10px;
	position: relative;
	vertical-align: middle;
	width: 30px;
}

.message-bar .message-area {
	display: table-cell;
}

.message-area .input-group .form-control {
	background-color: #fff;
	border: 1px solid #eaeaea;
	box-shadow: none;
	color: #555;
	display: block;
	font-size: 14px;
	height: 44px;
	margin: 0;
	padding: 6px 12px;
	resize: none;
}

.message-area .btn {
	height: 44px;
	width: 50px;
}

.profile-right {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
}

.display-table {
	display: table;
	table-layout: fixed;
	border-spacing: 0;
	width: 100%;
	height: 100%;
}

.table-row {
	display: table-row;
	height: 100%;
}

.table-body {
	position: relative;
	height: 100%;
	width: 100%;
}

.table-content {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.chat-profile-img {
	padding: 30px;
	position: relative;
	text-align: center;
}

.edit-profile-img {
	height: 120px;
	margin: 0 auto;
	position: relative;
	width: 120px;
	cursor: pointer;
}

.edit-profile-img img {
	border-radius: 50%;
	height: auto;
	margin: 0;
	width: 120px;
}

.change-img {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	color: #fff;
	display: none;
	height: 100%;
	left: 0;
	line-height: 120px;
	position: absolute;
	top: 0;
	width: 100%;
}

.edit-profile-img:hover .change-img {
	display: block;
}

.edit-profile-img img {
	height: auto;
	margin: 0;
	width: 120px;
	border-radius: 50%;
}

.user-name {
	color: #333;
}

.edit-btn {
	border-radius: 40px;
	height: 36px;
	position: absolute;
	right: 15px;
	top: 15px;
	width: 36px;
}

.chat-profile-info {
	padding: 15px;
}

.user-det-list {
	list-style: none;
	padding: 0;
}

.user-det-list>li {
	padding: 6px 15px;
}

.user-det-list .text-muted {
	color: #888;
}

.transfer-files .tab-content {
	padding-top: 0;
}

.files-list {
	list-style: none;
	padding-left: 0;
}

.files-list>li {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	margin: 2px 0 0;
	padding: 10px;
}

.files-cont {
	position: relative;
}

.inv-dec{
	padding-left: 50px !important;
  }

.file-type {
	height: 48px;
	position: absolute;
	width: 48px;
}

.files-icon {
	background-color: #f3f7f9;
	border: 1px solid #e4eaec;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}

.files-icon i {
	color: #76838f;
	font-size: 16px;
}

.files-info {
	padding: 0 30px 0 50px;
}

.file-name a {
	color: #333;
}

.files-action {
	display: none;
	height: 30px;
	list-style: none;
	padding-left: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 10px;
	width: 30px;
}

.files-list>li:hover .files-action {
	display: block;
}

.file-date {
	color: #888;
	font-size: 14px;
}

.file-author a {
	color: #00c5fb;
	font-size: 14px;
	text-decoration: underline;
}

.files-action .dropdown-menu {
	left: auto;
	right: 0;
}

.files-action>li>a {
	color: #777;
}

.chat-img-attach {
	border-radius: 4px;
	margin-bottom: 12px;
	margin-left: 12px;
	position: relative;
	float: right;
}

.chat-img-attach img {
	border-radius: 4px;
	display: inline-block;
	height: auto;
	max-width: 100%;
}

.chat-placeholder {
	background: rgba(69, 81, 97, 0.6);
	border-radius: 4px;
	color: #fff;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	left: 0;
	padding: 13px;
	position: absolute;
	top: 0;
	width: 100%;
}

.chat-img-name {
	font-size: 14px;
	font-weight: normal;
	line-height: 16px;
}

.chat-file-desc {
	font-size: 14px;
}

.chat-right .chat-content.img-content {
	background-color: transparent;
	border: 0;
	color: #76838f;
	padding: 0;
	text-align: right;
}

.chat-right .img-content .chat-time {
	color: #a3a3a3;
}

.chat-left .chat-img-attach {
	float: left;
}

.chat-left .chat-img-attach {
	float: left;
	margin-left: 0;
	margin-right: 12px;
}

.input-group .form-control-lg.form-control {
	border-radius: 4px 0 0 4px;
	height: 46px;
}

.chat-user-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.chat-user-list .media {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	padding: 10px;
	background-color: #fff;
}

.chat-user-list .media:hover {
	background-color: #f7f7f7;
}

.designation {
	color: #9e9e9e;
	font-size: 14px;
}

.online-date {
	color: #9e9e9e;
	font-size: 14px;
}

.drop-zone {
	width: 100%;
	height: 205px;
	border: 2px dashed #adb7be;
	text-align: center;
	padding: 25px 0 0;
	margin-bottom: 20px;
}

.drop-zone .drop-zone-caption {
	font-weight: normal;
}

.upload-list {
	padding: 0;
	list-style: none;
	margin: 0;
}

.upload-list .file-list {
	background-color: #fff;
	border-top: 1px solid #e3e3e3;
	padding: 10px 0;
}

.upload-list .file-list:first-child {
	border-top: none
}

.upload-list .upload-wrap {
	position: relative;
	padding: 0 20px 0 0;
	margin: 0 0 5px
}

.upload-list .file-name,
.upload-list .file-size {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
}

.upload-list .file-name {
	padding-right: 15px;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis
}

.upload-list .file-size {
	color: #888;
}

.upload-list .file-close {
	border: none;
	background: none;
	color: #ccc;
	position: absolute;
	right: 0;
	top: 2px;
}

.upload-list .file-close:hover {
	color: #f62d51
}

.upload-list .upload-process {
	font-size: 14px;
	color: #888;
}

.upload-list .progress {
	margin-bottom: 5px;
}

.upload-list .file-name i {
	color: #888;
	margin: 0 5px 0 0;
	vertical-align: middle;
}

.upload-drop-zone {
	background-color: #fff;
	border: 2px dashed #e3e3e3;
	color: #ccc;
	height: 200px;
	line-height: 200px;
	margin-bottom: 20px;
	text-align: center;
}

.upload-drop-zone.drop {
	color: #222;
	border-color: #222;
}

.upload-text {
	font-size: 16px;
	margin-left: 10px;
}

.files-share-list {
	border: 1px solid #eaeaea;
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 15px;
}

/*-----------------
	20. Focus Label
-----------------------*/

.form-focus {
	height: 50px;
	position: relative;
}

.form-focus .focus-label {
	font-size: 14px;
	font-weight: normal;
	opacity: 0.4;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 12px;
	top: -8px;
	z-index: 1;
	color: black;
	margin-bottom: 0;
}

.form-focus.focused .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -14px;
	font-size: 14px;
	z-index: 1;

}

.form-focus .form-control:focus~.focus-label,
.form-focus .form-control:-webkit-autofill~.focus-label {
	opacity: 1;
	font-weight: 300;
	top: -10px;
	font-size: 14px;
	z-index: 1;
}

.form-focus .form-control {
	height: 50px;
	padding: 8px 12px 6px;
	width: 210px;
	margin-top: 24px;
}

.focus-label {
	width: 140px;
	color: #838697;
}

.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}

.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -49px;
	font-size: 14px;
	z-index: 1;
	color: #81849D;
	margin-left: -9px;

}


.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}

/*-----------------
	21. Leave
-----------------------*/

.filter-row .btn {
	min-height: 50px;
	padding: 12px;
	/*text-transform: uppercase;*/
}

.action-label .label {
	display: inline-block;
	min-width: 85px;
	padding: 0.5em 0.6em;
}

.action-label i {
	margin-right: 3px;
}

.action-label .dropdown-menu .dropdown-item {
	padding: 5px 10px;

}

/*-----------------
	22. Employee
-----------------------*/

.action-icon {
	color: #777;
	font-size: 16px;
	display: inline-block;
}

.table .dropdown-menu {
	font-size: 14px;
	min-width: 120px;
	padding: 0;
	transform: translate(0px, 9.6667px);
	position: sticky !important;

}

.table .dropdown-menu.dropdownaction{
	transform: translate(0px, 5px) !important;
}

.table .dropdown-menu.recordstatus{
	transform: translate(0px, 5px) !important;
}

.table .dropdown-menu .dropdown-item {
	padding: 5px 10px;

}

.dropdown-action .dropdown-toggle::after {
	display: none;
}

.profile-widget {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 20px;
	text-align: center;
	position: relative;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.profile-widget .user-name>a {
	color: #333;
}

.dropdown.profile-action {
	position: absolute;
	right: 5px;
	text-align: right;
	top: 10px;
}

.profile-action .dropdown-toggle::after {
	display: none;
}

.profile-img {
	cursor: pointer;
	height: 80px;
	margin: 0 auto;
	position: relative;
	width: 80px;
}

.profile-img .avatar {
	font-size: 16px;
	height: 80px;
	line-height: 80px;
	margin: 0;
	width: 80px;
}

.mobile-no>a {
	color: #777;
	display: inline-block;
}

.staff-mail>a {
	color: #777;
	display: inline-block;
	margin-top: 5px;
}

.staff-action-btns {
	margin-top: 10px;
}

.staff-id {
	font-size: 14px;
	font-weight: normal;
	margin-top: 5px;
}

.view-icons {
	float: right;
	margin-right: 10px;
}

.view-icons .btn {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	color: #888;
	font-size: 16px;
	margin-right: 5px;
	min-width: 40px;
	padding: 4px;
}

.view-icons .btn.active {
	color: #333;
}

/*-----------------
	23. Events
-----------------------*/

.calendar {
	float: left;
	margin-bottom: 0;
}

/* .fc-view {
	margin-top: 30px;
} */

.none-border .modal-footer {
	border-top: none;
}

.fc-toolbar h2 {
	font-size: 16px;
	font-weight: normal;
	    font-family: "Open_Sans", Tofu;
	line-height: 30px;
	text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
	    font-family: "Open_Sans", Tofu;
}

.fc-day {
	background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}

.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}

.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}

.fc-text-arrow {
	font-family: inherit;
	font-size: 14px;
}

.fc-state-hover {
	background: #f3f3f3;
}

.fc-state-highlight {
	background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #00c5fb !important;
	color: #fff !important;
	text-shadow: none !important;
}

.fc-cell-overlay {
	background: #f0f0f0;
}

.fc-unthemed .fc-today {
	background: #fff;
}

.fc-event {
	border-radius: 0%;
	border: none;
	cursor: move;
	font-size: 14px;
	margin-top: 0%;
	padding: 0%;
	text-align: center;
	background-color: black;
}
.pending-home-event {
    background-color:  #f9e4f4;
	height: 25px;
	color: black;
	border:2px solid #f9ddf5;
	font-size: 14px;
	font-family: "Open_Sans" !important;
	position:relative;
	top:50%;
  padding:4px;
}
.approve-home-event {
    background-color:  #96bc93;;
	height: 25px;
	color: #fff;
	border:2px solid #7dbc7b;

	font-size: 14px;
	font-family: "Open_Sans" !important;
}
.holiday-home-event {
    background-color:  #83bcf6;;
	height: 25px;
	color: #fff;
	font-size: 14px;
	font-family: "Open_Sans" !important;
	position:relative;
	top:50%
}
.pending-event {
    /* background-color:  #f0ee92;; */
	height: 20px;
	color: #212529;
	font-size: 14px;
	font-family: "Open_Sans" !important;
}

  .reject-event {
    background-color:#EF4444;
	height: 27px;
  }

  .approve-event {
    background-color: #689F38;
	width: 10px;
    height: 10px;
    color: #fff;
    border-radius: 90%;
  }
  .other-event {
    background-color: violet;
	height: 27px;
  }


.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 6px 10px;
}

.external-event.bg-primary {
	background-color: rgba(127, 193, 252, 0.3) !important;
	color: #7fc1fc;
}

.external-event.bg-success {
	background-color: rgba(75, 211, 150, 0.3) !important;
	color: #4bd396;
}

.external-event.bg-info {
	background-color: rgba(58, 201, 214, 0.3) !important;
	color: #3ac9d6;
}

.external-event.bg-warning {
	background-color: rgba(249, 200, 81, 0.3) !important;
	color: #f9c851;
}

.external-event.bg-danger {
	background-color: rgba(245, 112, 122, 0.3) !important;
	color: #f5707a;
}

.external-event.bg-pink {
	background-color: rgba(240, 98, 146, 0.3) !important;
	color: #f06292;
}

.external-event.bg-purple {
	background-color: rgba(107, 95, 181, 0.3) !important;
	color: #6b5fb5;
}

.external-event.bg-inverse {
	background-color: rgba(59, 62, 71, 0.3) !important;
	color: #3b3e47;
}

.external-event.bg-orange {
	background-color: rgba(255, 152, 0, 0.3) !important;
	color: #ff9800;
}

.external-event.bg-brown {
	background-color: rgba(141, 110, 99, 0.3) !important;
	color: #8d6e63;
}

.external-event.bg-teal {
	background-color: rgba(38, 166, 154, 0.3) !important;
	color: #26a69a;
}

.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: normal;
	    font-family: "Open_Sans", Tofu;
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: normal;
	    font-family: "Open_Sans", Tofu;
}

.event-form .input-group .form-control {
	height: 40px;
}

/*-----------------
	24. Profile
-----------------------*/

.profile-details {
	text-align: center;
}

.personal-info li .title {
	color: #4f4f4f;
	float: left;
	font-weight: normal;
	margin-right: 30px;
	width: 25%;
}

.personal-info li .text {
	color: #8e8e8e;
	display: block;
	overflow: hidden;
}

.personal-info li {
	margin-bottom: 10px;
}

.personal-info {
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}

.profile-view {
	position: relative;
}

.profile-view .profile-img-wrap {
	height: 120px;
	width: 120px;
}

.profile-view .profile-img {
	width: 120px;
	height: 120px;
}

.profile-view .profile-img .avatar {
	font-size: 16px;
	height: 120px;
	line-height: 120px;
	margin: 0;
	width: 120px;
}

.profile-view .profile-basic {
	margin-left: 140px;
	padding-right: 50px;
}

.profile-view .pro-edit {
	position: absolute;
	right: 0;
	top: 0;
}

.edit-icon {
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	float: right;
	font-size: 14px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
	margin-right:6px;
	width: 26px;
	cursor:pointer;
}
.icon-center{
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	font-size: 14px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
	margin-right:6px;
	width: 26px;
}
.action-icon {
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	float: left;
	font-size: 14px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
  margin-right:6px;
	width: 26px;
	cursor:pointer;
	margin-bottom:5px;
}

.icon-center:hover {
	background-color: #fff;
	border-color: #00c5fb;
	color: #fff;
}.edit-icon:hover {
	background-color: #fff;
	border-color: #00c5fb;
	color: #fff;
}
.action-icon:hover {
	background-color: #fff;
	border-color: #00c5fb;
	color: #fff;
}

.delete-icon {
	color: #e30b0b;
	float: right;
	font-size: 16px;
}

.delete-icon:hover {
	color: #e30b0b;
}

.staff-msg {
	margin-top: 30px;
}

.experience-box {
	position: relative;
}

.experience-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.experience-list::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 8px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.experience-list>li {
	position: relative;
}

.experience-list>li:last-child .experience-content {
	margin-bottom: 0;
}
.employee-name{
	color: #3e75b3 !important;
	font-size: 16px !important;

}
.employee-no{
	color: #3e75b3 !important;
	font-size:12px;
}
.experience-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}

.experience-list>li .experience-user {
	background: #fff;
	height: 10px;
	left: 4px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 4px;
	width: 10px;
}

.experience-list>li .experience-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}

.experience-list>li .experience-content .timeline-content {
	color: #9e9e9e;
}

.experience-list>li .experience-content .timeline-content a.name {
	color: #616161;
	font-weight: normal;
}

.experience-list>li .time {
	color: #bdbdbd;
	display: block;
	font-size: 14px;
	line-height: 1.35;
}

.before-circle {
	background-color: #ddd;
	border-radius: 50%;
	height: 10px;
	width: 10px;
}

.skills>span {
	border: 1px solid #ccc;
	border-radius: 500px;
	display: inline-block;
	margin-bottom: 10px;
	padding: 6px 12px;
	text-align: center;
}

.profile-info-left {
	border-right: 2px dashed #ccc;
}

.bootstrap-tagsinput {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 0;
	box-shadow: unset;
	display: block;
	min-height: 44px;
	padding: 6px 6px 0;
}

.bootstrap-tagsinput .badge {
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 6px;
	padding: 10px 15px;
}

.add-more a {
	color: #00c5fb;
}

.add-more a:hover {
	color: #00b7ed;
}

.avatar-box {
	float: left;
}

.pro-overview .personal-info li .title {
	width: 50%;
}

.profile-box {
	min-height: 250px;
}

/*-----------------
	25. Notifications
-----------------------*/

.notifications {
	padding: 0;
}

.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}

.notifications .notification-heading {
	margin: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.45;
	color: #616161;
}

.notifications .notification-time {
	font-size: 14px;
	line-height: 1.35;
	color: #bdbdbd;
}

.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.notifications ul.notification-list>li {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
	border-bottom: none;
}

.notifications ul.notification-list>li a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
	background-color: #fafafa;
}

.notifications ul.notification-list>li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}

.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
	border-bottom: none;
}

.notifications .media a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications .media a:hover {
	background-color: #fafafa;
}

.notifications .media-list .media-left {
	padding-right: 8px;
}

.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
	font-size: 14px;
	height: 32px;
	line-height: 32px;
	padding-left: 12px;
	padding-right: 12px;
}

.topnav-dropdown-header .notification-title {
	color: #333;
	display: block;
	float: left;
	font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
	color: #f83f37;
	float: right;
	font-size: 14px;
	text-transform: uppercase;
}

.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}

.user-menu.nav>li>a .badge {
	color: #fff;
	font-weight: normal;
	position: absolute;
	right: 3px;
	top: 6px;
}

.user-menu.nav>li>a .badge {
	background-color: #7460ee;
}

.user-menu.nav>li>a>i {
	font-size: 16px;
	line-height: 60px;
}

.noti-details {
	color: #989c9e;
	margin-bottom: 0;
}

.noti-title {
	color: #333;
}

.noti-time {
	margin: 0;
}

/*-----------------
	26. Roles & Permissions
-----------------------*/

.roles-menu {
	margin-top: 20px;
}

.roles-menu>ul {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.roles-menu>ul>li {
	display: block;
	position: relative;
}

.roles-menu>ul>li a {
	border-left: 3px solid transparent;
	color: #333;
	display: block;
	padding: 10px 15px;
	position: relative;
}

.roles-menu>ul>li.active a {
	border-color: #00c5fb;
	color: #00c5fb;
}

.roles-menu>ul>li a:hover {
	background-color: #eee;
	border-color: #00c5fb;
}

.role-action {
	float: right;
	display: none;
}

.roles-menu>ul>li a:hover .role-action {
	display: block;
}

.slide-nav .sidebar {
	margin-left: 0;
}

/*-----------------
	27. Chat Right Sidebar
-----------------------*/

.fixed-header .custom-menu {
	margin: 1px 0 0;
}

.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}

.list-box {
	list-style: none;
	padding-left: 0;
}

.list-item {
	border-bottom: 1px solid #eaeaea;
	padding: 10px;
	position: relative;
}

.list-left {
	height: 48px;
	position: absolute;
	width: 48px;
}

.files-icon {
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}

.files-icon i {
	color: #777;
	font-size: 16px;
}

.list-body {
	padding: 0 0 0 50px;
}

.message-author {
	color: #333;
	float: left;
	font-weight: normal;
	width: 175px;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.message-time {
	color: #888;
	float: right;
	font-size: 14px;
}

.message-content {
	color: #333;
	font-size: 14px;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.files-action .dropdown-menu {
	left: auto;
	right: 0;
}

/*-----------------
	28. Projects
-----------------------*/

.team-members {
	display: inline-flex;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}

.team-members>li:first-child a {
	margin-left: 0;
}

.team-members>li>a {
	border: 2px solid #fff;
	border-radius: 100%;
	display: block;
	height: 30px;
	overflow: hidden;
	width: 30px;
}

.team-members .all-users {
	line-height: 28px;
	opacity: 0.8;
}

.all-users {
	background-color: #ff5e3a;
	color: #fff;
	font-size: 14px;
	font-weight: normal;
	line-height: 34px;
	text-align: center;
}

.all-users:hover,
.all-users:active {
	color: #fff;
}

.team-members img {
	width: 100%;
}

.project-title {
	margin: 0 0 5px;
}

.project-title>h5 {
	font-size: 14px;
	margin-bottom: 0;
	margin-top: 0;
}

.project-title>a {
	color: #333;
}

.avatar-dropdown .dropdown-menu {
	min-width: unset;
	width: 100px;
	padding: 0;
}

.avatar-dropdown .avatar-group {
	min-height: 88px;
	padding: 2px;
	white-space: normal;
}

.avatar-dropdown .avatar-group .avatar {
	margin: 2px;
}

.avatar-dropdown .avatar-pagination {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar-dropdown .page-link {
	padding: .3rem 0.55rem;
	border-radius: 0 !important;
	border: 0;
}

.avatar-dropdown .dropdown-toggle::after {
	display: none;
}

/*-----------------
	29. Invoice
-----------------------*/

.invoice-details,
.invoice-payment-details>li span {
	float: right;
	text-align: right;
}

.table .team-members {
	flex-wrap: nowrap;
}

.table .team-members>li>a {
	width: 32px;
	height: 32px;
}

.table .team-members .all-users {
	line-height: 30px;
}

.attach-files>ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.attach-files li {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}

.attach-files li img {
	width: 50px;
}

.file-remove {
	color: #f00;
	position: absolute;
	right: -6px;
	top: -7px;
}

.attach-files li:last-child {
	margin-right: 0;
}

.inv-logo {
	height: auto;
	margin-bottom: 20px;
	max-height: 100px;
	width: auto;
}

/*-----------------
	30. Task
-----------------------*/

.task-chat-contents {
	background-color: #fff;
}

.task-chat-contents .chat-left .chat-content {
	max-width: 100%;
}

.task-chat-view {
	border-left: 1px solid #eaeaea;
}

.task-left-sidebar {
	width: 58.3333%;
}

.task-right-sidebar {
	width: 41.6667%;
}

.task-chat-view .chat-left .chat-content {
	border: 0;
	padding: 0;
}

.task-chat-view .chat-left .chat-body {
	margin-left: 50px;
}

.task-chat-view .chat-date {
	background-color: #fff;
}

.task-chat-view .chats {
	padding: 15px 15px 30px;
}

.task-chat-view .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.followers-add {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	color: #ccc;
	display: inline-block;
	font-size: 16px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.followers-add:hover {
	border: 1px solid #8c8c8c;
	color: #8c8c8c;
}

.completed-task-msg {
	margin-bottom: 30px;
	margin-left: 50px;
}

.task-chat-contents .chat.chat-left {
	margin-bottom: 20px;
}

.file-attached {
	color: #333;
	font-size: 14px;
}

.file-attached i {
	font-size: 14px;
}

.task-attach-img>img {
	border: 1px solid #eaeaea;
	max-width: 100%;
}

.attach-img-download {
	margin-bottom: 5px;
}

.task-chat-view .chat-left .chat-time {
	color: #a3a3a3;
	display: inline-block;
	font-size: 14px;
}

.task-chat-user {
	color: #333;
	font-size: 14px;
}

.task-chat-view .chat-content>p {
	font-size: 14px;
	margin-bottom: 0;
}

.task-time {
	color: #a3a3a3;
	display: inline-block;
	font-size: 14px;
}

.task-success {
	color: #55ce63;
	font-size: 14px;
}

.task-success a {
	color: #55ce63;
}

.task-attach-img {
	margin-bottom: 20px;
}

.task-assign {
	float: left;
}

.assign-title {
	float: left;
	margin-right: 10px;
	margin-top: 5px;
}

.task-assign>a {
	float: left;
}

.followers-add>i {
	line-height: 28px;
}

.task-followers {
	display: flex;
	margin-top: 15px;
}

.followers-title {
	margin: 5px 10px 0 0;
}

.task-line {
	border-color: #eaeaea;
}

.task-information {
	color: #a3a3a3;
	font-size: 14px;
	line-height: 17px;
	min-width: 1px;
	margin-left: 50px;
}

.task-info-line {
	overflow-wrap: break-word;
}

.task-user {
	color: #888;
	font-weight: normal;
}

.task-information .task-time {
	display: inline-block;
	padding-left: 10px;
}

.task-btn-right {
	display: none !important;
}

.task:hover .task-btn-right {
	display: table-cell !important;
}

.task-action-btn.task-check {
	text-align: left !important;
	width: 40px;
}

.add-task-btn {
	cursor: pointer;
	padding: 6px 10px;
	text-transform: uppercase;
}

.action-circle {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 100%;
	height: 20px;
	width: 20px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.action-circle .material-icons {
	color: #ccc;
	font-size: 16px;
	vertical-align: -3px;
}

.action-circle.completed {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.action-circle.completed .material-icons {
	color: #fff;
}

.action-circle.large {
	height: 24px;
	width: 24px;
}

.action-circle.large .material-icons {
	font-size: 14px;
	vertical-align: -4px;
}

.task-wrapper {
	padding: 20px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}

.task-wrapper .mark-all-tasks {
	text-align: right;
	padding-bottom: 15px;
}

.task-wrapper .mark-all-tasks .mark-all-tasks-container {
	overflow: hidden;
	position: relative;
}

.task-wrapper .mark-all-tasks .mark-all-btn {
	cursor: pointer;
	display: inline-block;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
	color: #35BA67;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
	color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
	border: 1px solid #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle .material-icons {
	color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-up {
	-webkit-transform: translateY(-30px);
	-moz-transform: translateY(-30px);
	-ms-transform: translateY(-30px);
	-o-transform: translateY(-30px);
	transform: translateY(-30px);
	position: absolute;
	right: 0;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-down {
	-webkit-transform: translateY(30px);
	-moz-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
	position: absolute;
	right: 0;
}

.task-wrapper .task-list-header h3,
.task-wrapper .task-list-footer h3 {
	font-size: 16px;
	font-weight: normal;
	color: #666;
}

.task-wrapper .task-list-body {
	max-height: 500px;
	overflow: auto;
}

.task-wrapper #task-list {
	list-style: none;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #eaeaea;
}

.task-wrapper #task-list li {
	margin: 0;
	padding: 0;
}

.task-wrapper #task-list li .task-container {
	display: table;
	background: #fff;
	padding: 8px 15px;
	width: 100%;
	border: 1px solid #eaeaea;
	border-bottom: none;
	box-sizing: border-box;
	position: relative;
	-webkit-transition: background 200ms ease;
	-moz-transition: background 200ms ease;
	-ms-transition: background 200ms ease;
	-o-transition: background 200ms ease;
	transition: background 200ms ease;
}

.task-wrapper #task-list li .task-container .task-label {
	display: table-cell;
	font-weight: normal;
	vertical-align: middle;
	color: #333;
	word-break: break-all;
}

.task-wrapper #task-list li .task-container .task-action-btn {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover {
	border: 1px solid #8c8c8c;
	background: #fff;
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover .material-icons {
	color: #8c8c8c;
}

.task-wrapper #task-list li .task-container:hover {
	background: #EBEEEF;
}

.task-wrapper #task-list li.completed .task-container {
	background: #f9f9f9;
}

.task-wrapper #task-list li.completed .task-container .complete-btn {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn .material-icons {
	color: #fff;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover .material-icons {
	color: #fff;
}

.task-wrapper #task-list li.completed .task-container .task-label {
	color: #ccc;
}

.task-wrapper #task-list li.new .task-container {
	-webkit-animation: taskHighlighter 2000ms linear 1;
	animation: taskHighlighter 2000ms linear 1;
}

.task-wrapper .task-list-footer {
	position: relative;
}

.task-wrapper .task-list-footer .add-task-btn-wrapper {
	text-align: center;
}

.task-wrapper .task-list-footer .add-task-btn {
	cursor: pointer;
	border: 2px solid #9B1BCC;
	display: inline-block;
	height: 35px;
	width: 35px;
	border-radius: 10px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.task-wrapper .task-list-footer .add-task-btn.hide {
	opacity: 1;
	visibility: visible;
	display: block;
}

.task-wrapper .task-list-footer .add-task-btn:hover {
	background: #9B1BCC;
}

.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
	color: #EBEEEF;
}

.task-wrapper .task-list-footer .add-task-btn .material-icons {
	color: #9B1BCC;
	font-size: 34px;
}

.task-wrapper .task-list-footer .new-task-wrapper {
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	max-height: 0;
	overflow: hidden;
}

.task-wrapper .task-list-footer .new-task-wrapper::after {
	content: "";
	display: block;
	clear: both;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea {
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #eaeaea;
	border-top: 0;
	height: 45px;
	resize: none;
	padding: 10px 15px;
	margin-bottom: 20px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
	border: 1px solid #bfbfbf;
	outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
	border: 1px solid #D93737;
	outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper .error-message {
	color: #D93737;
	font-style: italic;
}

.task-wrapper .task-list-footer .new-task-wrapper .btn {
	padding: 6px 12px;
	float: right;
	cursor: pointer;
}

.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
	background: #fff;
	border: 1px solid #ccc;
}

.task-wrapper .task-list-footer .new-task-wrapper.visible {
	max-height: 300px;
	overflow: auto;
}

.notification-popup {
	z-index: 1051;
	position: fixed;
	top: 20px;
	right: 10px;
	width: 300px;
	display: inline-block;
	background: #1F8FEF;
	border: 1px solid #1082e4;
	color: #fff;
	padding: 20px;
	opacity: .8;
	border-radius: 2px;
	box-sizing: border-box;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.notification-popup.success {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.notification-popup p {
	margin-top: 0;
	margin-bottom: 0px;
}

.notification-popup .task {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
	display: inline-block;
}

.notification-popup .notification-text {
	font-size: 14px;
	display: inline-block;
	overflow: hidden;
}

.notification-popup.hide {
	opacity: 0;
	visibility: hidden;
}

.sidebar-overlay {
	display: none;
	position: absolute;
	z-index: 90;
	height: 100%;
	left: 0;

	width: 100%;
	z-index: 1040;
	background-color: rgba(0, 0, 0, 0.6);
}

.sidebar-overlay.opened {
	display: block;
}

.sidebar-menu.opened {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	-ms-transform: translateX(0px);
	-o-transform: translateX(0px);
	transform: translateX(0px);
	margin-left: 0;
}

html.menu-opened {
	overflow: hidden;
}

html.menu-opened body {
	overflow: hidden;
}

.task-chat-view.opened {
	margin-right: 0;
}

.chat-profile-view.opened {
	margin-right: 0;
}

.task-header {
	display: flex;
}

.task-header .assignee-info {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 10px;
	display: inline-flex;
	flex: 0 1 180px;
	padding: 5px 10px 5px 5px;
	position: relative;
}

.task-header .assignee-info>a {
	align-items: center;
	color: #333;
	display: flex;
}

.task-header .task-head-title {
	color: #8e8e8e;
	font-size: 14px;
}

.task-assignee {
	font-size: 14px;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.assignee-info:hover {
	border-color: #e3e3e3;
}

.remove-icon {
	align-items: center;
	background-color: #e3e3e3;
	border-radius: 50%;
	color: #333;
	cursor: pointer;
	display: flex;
	height: 20px;
	justify-content: center;
	margin-right: 10px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	visibility: hidden;
	width: 20px;
}

.remove-icon:hover {
	background-color: #d3d3d3;
}

.task-header .assignee-info:hover .remove-icon {
	visibility: visible;
}

.remove-icon i {
	color: #8e8e8e;
	font-size: 14px;
}

.due-icon span {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	color: #ccc;
	display: flex;
	height: 30px;
	justify-content: center;
	line-height: 30px;
	margin-right: 10px;
	width: 30px;
}

.due-icon span i {
	font-size: 16px;
}

.task-due-date {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 10px;
	display: flex;
	flex: 0 0 160px;
	margin-left: 10px;
	padding: 5px 10px 5px 5px;
	position: relative;
}

.task-due-date:hover {
	border-color: #e3e3e3;
}

.task-header .task-due-date>a {
	align-items: center;
	color: #333;
	display: flex;
}

.due-info .due-date {
	color: #f62d51;
	font-size: 14px;
}

.task-header .task-due-date:hover .remove-icon {
	visibility: visible;
}

.task-desc {
	align-items: flex-start;
	display: flex;
	margin-bottom: 15px;
	margin-top: 5px;
}

.task-textarea {
	display: flex;
	width: 100%;
}

.task-desc-icon {
	color: #ccc;
	margin-right: 10px;
	margin-top: 5px;
}

.task-textarea .form-control {
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: 14px;
	resize: none;
}

.task-textarea:hover .form-control {
	border-color: #e3e3e3;
}

.task-complete-btn {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #333;
	display: flex;
	font-size: 14px;
	padding: 6px 10px;
}

.task-complete-btn:hover {
	background-color: #f7f7f7;
	color: #333;
}

.task-complete-btn i {
	font-size: 16px;
	margin-right: 3px;
}

.task-completed {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: #fff;
}

.task-completed:hover {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: #fff;
}

/*-----------------
	31. Project View
-----------------------*/

.project-user .list-box {
	margin: 0;
}

.project-files i {
	color: #76838f;
	font-size: 50px;
}

.project-user .list-item {
	border: 0 none;
	padding: 10px 0;
}

.table-border {
	border: 1px solid #ddd;
}

.file-size {
	color: #888;
}

.project-task .task-wrapper {
	padding: 0;
}

.project-task .tab-content {
	min-height: 200px;
}

.uploaded-box {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: block;
	margin-bottom: 20px;
	padding: 4px;
}

.uploaded-box .uploaded-img-name {
	color: #333;
	padding: 9px;
	text-align: center;
}

/*-----------------
	32. Payslip
-----------------------*/

.payslip-title {
	margin-bottom: 20px;
	text-align: center;
	text-decoration: underline;
	text-transform: uppercase;
}

/*-----------------
	33. Attendance
-----------------------*/

.first-off {
	white-space: pre-wrap;
}

.stats-info {
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 15px;
	text-align: center;
}

.stats-info h6 {
	color: #1f1f1f;
	font-size: 14px;
	font-weight: normal;
	line-height: 18px;
	margin-bottom: 5px;
}

.stats-info h4 {
	font-size: 16px;
	margin-bottom: 0;
}

.stats-info h4 span {
	color: #727272;
	font-size: 14px;
}

.punch-det {
	background-color: #f9f9f9;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 10px 15px;
}

.punch-det h6 {
	line-height: 20px;
	margin-bottom: 0;
}

.punch-det p {
	color: #727272;
	font-size: 14px;
	margin-bottom: 0;
}

.punch-info {
	margin-bottom: 20px;
}

.punch-hours {
	align-items: center;
	background-color: #f9f9f9;
	border: 5px solid #e3e3e3;
	border-radius: 50%;
	display: flex;
	font-size: 16px;
	height: 120px;
	justify-content: center;
	margin: 0 auto;
	width: 120px;
}

.punch-btn-section {
	margin-bottom: 20px;
	text-align: center;
}

.punch-btn {
	border-radius: 50px;
	font-size: 16px;
	font-weight: normal;
	max-width: 100%;
	padding: 8px 40px;
}

.punch-status .stats-box {
	margin-bottom: 0;
}

.stats-list {
	height: 331px;
	overflow-y: auto;
}

.att-statistics .stats-info {
	margin-bottom: 5px;
}

.att-statistics .progress {
	height: 4px;
}

.statistics .row {
	margin-left: -5px;
	margin-right: -5px;
}

.statistics .row>div {
	padding-left: 5px;
	padding-right: 5px;
}

.stats-box {
	background-color: #f9f9f9;
	border: 1px solid #e3e3e3;
	margin-bottom: 15px;
	padding: 5px;
}

.stats-box p {
	margin: 0;
	font-size: 14px;
}

.stats-box>h6 {
	margin-bottom: 0;
}

.stats-info:last-child .progress {
	margin-bottom: 0;
}

.stats-info p {
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: 5px;
}

.recent-activity .res-activity-list {
	height: 328px;
	list-style-type: none;
	margin-bottom: 0;
	overflow-y: auto;
	padding-left: 30px;
	position: relative;
}

.recent-activity .res-activity-list li {
	margin-bottom: 15px;
	position: relative;
}

.recent-activity .res-activity-list li:last-child {
	margin-bottom: 0;
}

.recent-activity .res-activity-list li:before {
	width: 10px;
	height: 10px;
	left: -30px;
	top: 0px;
	border: 2px solid #00c5fb;
	margin-right: 15px;
	z-index: 2;
	background: #fff;
}

.recent-activity .res-activity-list li:before {
	content: "";
	position: absolute;
	border-radius: 100%;
}

.recent-activity .res-activity-list:after {
	content: "";
	border: 1px solid #e5e5e5;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 4px;
}

.recent-activity .res-activity-time {
	color: #bbb;
	font-size: 14px;
}

.recent-activity h6 {
	line-height: 14px;
	margin-bottom: 0;
}

.recent-activity p {
	font-size: 14px;
	margin-bottom: 0;
}

.half-day {
	width: 15px;
}

/*-----------------
	34. Ticket
-----------------------*/

.ticket-header {
	padding: 6px 0;
}

.ticket-priority {
	font-size: 14px;
}

.ticket-chat-view {
	width: 33.3333%;
}

.dataTables_wrapper.container-fluid {
	padding-left: 0;
	padding-right: 0;
	position: absolute;
}

/*-----------------
	35. Client Profile
-----------------------*/

.tab-box {
	border-bottom: 0;
	margin-bottom: 10px;
	padding: 0;
}

.team-members a.followers-add {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	color: #ccc;
	display: inline-block;
	font-size: 16px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

/*-----------------
	36. Inbox
-----------------------*/

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
	cursor: pointer;
}

.mail-list {
	list-style: none;
	padding: 0;
}

.mail-list>li>a {
	color: #333;
	display: block;
	padding: 10px;
}

.mail-list>li.active>a {
	color: #00c5fb;
	font-weight: normal;
}

.unread .name,
.unread .subject,
.unread .mail-date {
	color: #000;
	font-weight: normal;
}

.table-inbox .fa-star {
	color: #ffd200;
}

.table-inbox .starred.fa-star {
	color: #ffd200;
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
	border-bottom: 1px solid #f2f2f2;
	border-top: 0;
}

.table-inbox {
	font-size: 14px;
	margin-bottom: 0;
}

.note-editor.note-frame {
	border: 1px solid #ccc;
	box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}

.note-editor.note-frame.fullscreen {
	top: 60px;
}

.mail-title {
	font-weight: normal;
	text-transform: uppercase;
}

.form-control.search-message {
	border-radius: 4px;
	height: 38px;
	margin-left: 5px;
	width: 180px;
}

.table-inbox tr {
	cursor: pointer;
}

table.table-inbox tbody tr.checked {
	background-color: #ffffcc;
}

.mail-label {
	font-size: 16px !important;
	margin-right: 5px;
}

/*-----------------
	37. Mail View
-----------------------*/

.attachments {
	list-style: none;
	margin: 0;
	padding: 0;
}

.attachments li {
	border: 1px solid #eee;
	float: left;
	margin-bottom: 10px;
	margin-right: 10px;
	width: 200px;
}

.attach-file {
	color: #777;
	font-size: 70px;
	padding: 10px;
	text-align: center;
	min-height: 153px;
}

.attach-file>i {
	line-height: 133px;
}

.attach-info {
	background-color: #f4f4f4;
	padding: 10px;
}

.attach-filename {
	color: #777;
	font-weight: normal;
}

.attach-filesize {
	color: #999;
	font-size: 14px;
}

.attach-file img {
	height: auto;
	max-width: 100%;
}

.mailview-header {
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.mailview-footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding-top: 15px;
}

.mailview-footer .btn-white {
	min-width: 102px;
}

.sender-img {
	float: left;
	margin-right: 10px;
	width: 40px;
}

.sender-name {
	display: block;
}

.receiver-name {
	color: #777;
}

.right-action {
	text-align: right;
}

.mail-view-title {
	font-weight: normal;
	font-size: 16px;
	margin: 0;
}

.mail-view-action {
	float: right;
}

.mail-sent-time {
	float: right;
}

/*-----------------
	38. Voice call
-----------------------*/

.voice-call-avatar {
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 2;
}

.voice-call-avatar .call-avatar {
	margin: 15px;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3px;
	background-color: #fff;
}

.call-duration {
	display: inline-block;
	font-size: 30px;
	margin-top: 4px;
	position: absolute;
	left: 0;
}

.voice-call-avatar .call-timing-count {
	padding: 5px;
}

.voice-call-avatar .username {
	font-size: 16px;
	text-transform: uppercase;
}

.call-icons {
	text-align: center;
	position: relative;
}

.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}

.call-icons .call-items .call-item {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}

.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}

.call-icons .call-items .call-item a {
	color: #777;
	border: 1px solid #ddd;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	display: inline-block;
	font-size: 16px;
}

.call-icons .call-items .call-item a i {
	width: 18px;
	height: 18px;
}

.user-video {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}

.user-video img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}

.user-video video {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}

.my-video {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}

.my-video ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.my-video ul li {
	float: left;
	width: 120px;
	margin-right: 10px;
}

.my-video ul li img {
	border: 3px solid #fff;
	border-radius: 6px;
}

.end-call {
	position: absolute;
	top: 7px;
	right: 0;
}

.end-call a {
	background-color: #f06060;
	border-radius: 50px;
	color: #fff;
	display: inline-block;
	line-height: 10px;
	padding: 8px 25px;
	text-transform: uppercase;
}

.call-users {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}

.call-users ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.call-users ul li {
	float: left;
	width: 80px;
	margin-left: 10px;
}

.call-users ul li img {
	border-radius: 6px;
	padding: 2px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.call-mute {
	width: 80px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	display: inline-block;
	text-align: center;
	line-height: 80px;
	border-radius: 6px;
	font-size: 30px;
	color: #fff;
	display: none;
	top: 0;
	border: 3px solid transparent;
}

.call-users ul li a:hover .call-mute {
	display: block;
}

.call-details {
	margin: 10px 0 0px;
	display: flex;
}

.call-info {
	margin-left: 10px;
	width: 100%;
}

.call-user-details,
.call-timing {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.call-description {
	white-space: nowrap;
	vertical-align: bottom;
}

.call-timing {
	color: #727272;
	display: flex;
	font-size: 14px;
	margin-top: 1px;
	overflow: hidden;
	white-space: pre;
}

/*-----------------
	39. Video Call
-----------------------*/

.content-full {
	height: 100%;
	position: relative;
	width: 100%;
}

.video-window .fixed-header {
	padding: 0;
	border: 0;
}

.video-window .fixed-header .nav>li>a {
	padding: 18px 15px;
}

/*-----------------
	40. Outgoing Call
-----------------------*/

.call-box {
	display: block;
	background: #00c5fb;
	background: -moz-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -webkit-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -ms-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
	position: sticky;
	top: 0;
	z-index: 99;
	overflow-y: auto;
	overflow-x: hidden;
}

.call-box .call-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}

.call-box .call-wrapper .call-avatar {
	margin-bottom: 50px;
	cursor: pointer;
	animation: ripple-shadow 2s infinite;
	-webkit-animation: ripple-shadow 2s infinite;
}

.call-box .call-wrapper .call-user {
	margin-bottom: 50px;
}

.call-box .call-wrapper .call-user h4 {
	color: #fff;
}

.call-box .call-wrapper .call-user span {
	display: block;
	color: #fff;
	font-weight: normal;
	text-align: center;
}

.call-box .call-wrapper .call-items {
	display: flex;
	align-items: center;
	justify-content: center;
}

.call-box .call-wrapper .call-items .call-item {
	background-color: rgba(255, 255, 255, 0.2);
	border: 1px solid transparent;
	border-radius: 100%;
	color: #fff;
	line-height: 0;
	margin: 0 5px;
	padding: 15px;
}

.call-box .call-wrapper .call-items .call-item:hover {
	opacity: 0.9;
}

.call-box .call-wrapper .call-items .call-item:first-child {
	margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item:last-child {
	margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item.call-end {
	padding: 20px;
	margin: 30px 20px 0;
	background: #f06060;
	border: 1px solid #f06060;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}

.call-box .call-wrapper .call-items .call-item.call-start {
	padding: 20px;
	margin: 30px 20px 0;
	background: #55ce63;
	border: 1px solid #55ce63;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
	margin: 0 10px;
}

.call-box .call-avatar {
	border-radius: 100%;
	height: 150px;
	max-width: 150px;
	min-width: 150px;
	position: relative;
	width: 100%;
}

.call-box .btn {
	background: rgba(0, 0, 0, 0);
	transition: all 0.3s ease 0s;
}

@-webkit-keyframes ripple-shadow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}

	100% {
		-webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
	}
}

@keyframes ripple-shadow {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}

	100% {
		-moz-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	41. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}

/*-----------------
	42. Contacts
-----------------------*/

.contacts-header {
	background-color: #fff;
	border-bottom: 1px solid #eaeaea;
	padding: 10px 15px;
}

.contacts-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
}

.contacts-header .user-info a {
	color: #76838f;
	text-transform: uppercase;
}

.contact-left {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	width: 100%;
}

.contacts-list {
	position: relative;
	padding: 0 70px 0 20px;
}

.contact-list {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.contact-list>li {
	background-color: #fff;
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	padding: 10px;
}

.contact-cont {
	position: relative;
}

.contact-type {
	height: 48px;
	position: absolute;
	width: 48px;
}

.contact-icon {
	background-color: #f3f7f9;
	border: 1px solid #e4eaec;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}

.contact-icon i {
	color: #76838f;
	font-size: 16px;
}

.contact-info {
	padding: 0 30px 0 50px;
}

.contact-action {
	height: 30px;
	list-style: none;
	padding-left: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 10px;
	width: 30px;
}

.contact-date {
	color: #888;
	font-size: 14px;
}

.contact-author a {
	color: #1d6ad2;
	font-size: 14px;
	text-decoration: underline;
}

.contact-action .dropdown-menu {
	left: auto;
	right: 0;
}

.contact-alphapets {
	background-color: #fff;
	border-left: 1px solid #ddd;
	bottom: 0;
	height: 100%;
	overflow: hidden;
	padding-bottom: 114px;
	position: fixed;
	right: 0;
	top: 114px;
	width: 50px;
}

.contact-box {
	display: inline-table;
	height: 100%;
	padding: 30px 15px;
	position: relative;
	width: 100%;
}

.alphapets-inner {
	height: 100%;
	overflow: auto;
}

.alphapets-inner a {
	display: block;
	text-align: center;
	padding: 2px;
	color: #333;
}

/*-----------------
	43. Chat Sidebar
-----------------------*/

.chat-sidebar {
	background-color: #fff;
	border-left: 1px solid #eaeaea;
	width: 300px;
}

.chat-sidebar .chat-contents {
	background-color: #fff;
}

.chat-sidebar .chat-left .chat-content {
	border: 0;
	padding: 0;
	border-radius: 0 !important;
	background-color: transparent;
	max-width: 100%;
}

.chat-sidebar .chat-left .chat-body {
	margin-left: 50px;
	padding-right: 0;
}

.chat-sidebar .chat-date {
	background-color: #fff;
}

.chat-sidebar .chats {
	padding: 15px 15px 30px;
}

.chat-sidebar .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.chat-sidebar .chat.chat-left {
	margin-bottom: 20px;
}

.chat-sidebar .chat.chat-left:last-child {
	margin-bottom: 0;
}

.chat-sidebar .chat-left .chat-time {
	color: #888;
	display: inline-block;
	font-size: 14px;
}

.chat-sidebar .chat-content>p {
	font-size: 14px;
	margin-bottom: 0;
}

.chat-sidebar.opened {
	margin-right: 0;
}

/*-----------------
	44. Jobs
-----------------------*/

.apply-btn {
	background: #00c5fb;
	background: -moz-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -webkit-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: -ms-linear-gradient(left, #00c5fb 0%, #0253cc 100%);
	background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
	border-color: transparent;
	box-shadow: 0 4px 4px rgba(36, 37, 38, 0.08);
	color: #fff;
	position: absolute;
	right: 20px;
	top: 20px;
}

.job-list {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	display: block;
	margin-bottom: 35px;
	position: relative;
	transition: all 0.3s ease 0s;
}

.job-list-det {
	align-items: flex-start;
	display: flex;
	align-items: center;
	padding: 20px;
}

.job-list .job-list-desc {
	flex: 1 1 0;
}

.job-list h4.job-department {
	color: #777;
	font-size: 14px;
	margin-bottom: 0;
}

.job-list h3.job-list-title {
	color: #333;
	font-size: 16px;
	font-weight: normal;
	line-height: 18px;
}

.job-list .job-list-footer {
	background-color: #f9f9f9;
	border-radius: 0 0 4px 4px;
	position: relative;
	padding: 20px;
}

.job-list .job-list-footer ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.job-list .job-list-footer ul li {
	color: #777;
	display: inline-block;
	margin-right: 14px;
}

.job-list .job-list-footer ul li i {
	color: #777;
	margin-right: 3px;
	position: relative;
}

.job-list .job-list-footer ul li:last-child {
	margin-right: 0;
}

.page-wrapper.job-wrapper {
	margin-left: 0;
}

.job-types {
	background-color: transparent;
	border: 1px solid #00c5fb;
	color: #00c5fb;
	border-radius: 4px;
	display: inline-block;
	padding: 6px 12px;
	text-align: center;
}

/*-----------------
	45. Job Details
-----------------------*/

.job-info {
	background-color: #fff;
	border: 1px solid #efefef;
	float: left;
	margin: 0 0 30px;
	padding: 30px;
	position: relative;
	width: 100%;
}

.job-title {
	color: #585858;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 5px;
}

.job-info .job-dept {
	color: #737882;
	display: block;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 20px;
}

.job-post-det {
	display: inline-block;
	margin-bottom: 5px;
	width: 100%;
	color: #737882;
	list-style: none;
	margin: 0;
	padding: 0;
}

.job-content li {
	line-height: 28px;
}

.job-post-det li {
	float: left;
	list-style: none;
	margin-bottom: 10px;
	margin-right: 20px;
}

.job-det-info {
	background-color: #fff;
	margin: 0 0 15px;
	padding: 0 0 6px;
}

.info-list {
	color: #888;
	padding-top: 20px;
}

.info-list span {
	color: #00c5fb;
	float: left;
	font-size: 16px;
	line-height: 14px;
}

.info-list p {
	line-height: 1.7;
	margin: 0 0 0 40px;
}

.info-list>h5 {
	color: #585858;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 5px;
	margin-left: 40px;
	text-transform: uppercase;
}

.app-ends {
	color: #46cd38;
	font-size: 14px;
}

.job-btn {
	border: 2px solid #00c5fb;
	color: #00c5fb;
	display: block;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 20px;
	padding: 15px;
	text-transform: uppercase;
	transition: all 0.3s ease 0s;
}

.job-btn:hover {
	background-color: #00c5fb;
	color: #fff;
}

.job-widget {
	background-color: #fff;
	border: 1px solid #efefef;
	float: left;
	margin: 0 0 30px;
	padding: 30px;
	position: relative;
	width: 100%;
}

.job-desc-title h2 {
	font-size: 16px;
	margin-bottom: 0;
}

.job-widget h4 {
	color: #585858;
	font-size: 14px;
	font-weight: normal;
	margin: 0 0 20px;
}

.job-description>p {
	color: #727272;
	line-height: 28px;
}

.square-list {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.square-list li {
	color: #727272;
	line-height: 24px;
	list-style: none;
	margin: 10px 0 0 20px;
	position: relative;
}

.square-list li:first-child {
	margin-top: 0
}

.square-list li:before {
	    font-family: "Open_Sans", Tofu;
	margin: 0;
	position: relative;
	color: #00c5fb;
	float: left;
	margin-left: -20px;
	display: block;
	content: "\f0c8";
	font-size: 8px
}

.text-blue {
	color: #009ce7;
}

/*-----------------
	46. Notification Settings
-----------------------*/

.notification-list .list-group-item {
	padding: 15px;
}

.notification-list .status-toggle {
	float: right;
}

/*-----------------
	47. Leave Settings
-----------------------*/

.leave-item {
	max-width: 653px;
}

.leave-row {
	display: flex;
	margin-bottom: 15px;
}

.carry-forward-itemview {
	margin-bottom: 15px;
}

.earned-leave-itemview {
	margin-bottom: 15px;
}

.leave-row .leave-left {
	flex: 1 1 auto;
}

.leave-row .leave-left .input-box {
	max-width: 410px;
}

.leave-item .form-group {
	margin-bottom: 0;
}

.leave-right {
	align-items: center;
	display: flex;
	flex: 0 0 200px;
	justify-content: flex-end;
	margin-top: 31px;
	min-height: 44px;
	text-align: right;
}

.leave-right .btn {
	min-height: 44px;
	min-width: 75px;
}

.leave-right .btn+.btn {
	margin-left: 10px;
}

.leave-edit-btn {
	color: #216ef4;
	background-color: transparent;
	border: 0;
	padding: 0 6px;
	transition: unset;
}

.leave-edit-btn[disabled] {
	cursor: not-allowed;
	opacity: 0.65;
}

.leave-inline-form {
	align-items: center;
	display: flex;
	min-height: 44px;
}

.leave-header {
	align-items: center;
	color: #212536;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.leave-header .title {
	flex: 1 1 auto;
}

.leave-header .leave-action {
	flex: 1 1 auto;
	text-align: right;
}

.leave-table .l-name {
	width: 200px;
}

.leave-table .l-days {
	width: 140px;
}

.leave-box .subtitle {
	color: #8e8e8e;
	font-size: 14px;
	font-weight: normal;
	margin: 5px auto 0 5px;
}

.leave-duallist {
	background-color: #f9f9f9;
	border: 1px solid #e3e3e3;
	display: block;
	padding: 15px;
}

.card-title.with-switch {
	display: flex;
	justify-content: space-between;
}

.btn.leave-delete-btn {
	font-weight: normal;
	margin-left: 10px;
	min-height: 30px;
	padding: 2px 15px;
}

/*-----------------
	48. Termination
-----------------------*/

.add-group-btn {
	display: flex;
}

.add-group-btn .btn {
	align-items: center;
	display: flex;
	margin-left: 10px;
}

.add-group-btn .btn i {
	margin-right: 5px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #00c5fb;
	text-shadow: unset;
}

/*-----------------
	49. Loader
-----------------------*/

#loader-wrapper {
	background-color: #fff;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
}

#loader {
	background-position: center center;
	background-repeat: no-repeat;
	height: 200px;
	left: 50%;
	margin: -100px 0 0 -100px;
	position: absolute;
	top: 50%;
	width: 200px;
}

.loader-ellips {
	font-size: 16px;
	position: relative;
	width: 64px;
	margin: 100px auto 0;
}

.loader-ellips__dot {
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 0.5em;
	position: absolute;
	animation-duration: 0.5s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
	left: 0;
}

.loader-ellips__dot:nth-child(3) {
	left: 1.5em;
}

.loader-ellips__dot:nth-child(4) {
	left: 3em;
}

.loader-ellips__dot:nth-child(1) {
	animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
	animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
	animation-name: reveal;
	animation-direction: reverse;
}

@keyframes reveal {
	from {
		transform: scale(0.001);
	}

	to {
		transform: scale(1);
	}
}

@keyframes slide {
	to {
		transform: translateX(1.5em)
	}
}

.loader-ellips__dot:nth-child(1) {
	background: #404040;
}

.loader-ellips__dot:nth-child(2) {
	background: #00c5fb;
}

.loader-ellips__dot:nth-child(3) {
	background: #404040;
}

.loader-ellips__dot:nth-child(4) {
	background: #404040;
}

/*-----------------
	50. Payroll Items
-----------------------*/

.page-menu .nav-tabs>li>a {
	font-size: 16px;
}

.table-radius.table {
	width: 100%;
	background: #fff;
	border-spacing: 0;
	border-radius: 0.25rem;
	margin-bottom: 0;
}

.table-radius.table thead td,
.table-radius.table thead th {
	border-top: 0 none !important;
	padding: 20px !important;

}

.table-radius.table tbody td,
.table-radius.table tbody th {
	padding: 15px 20px !important;
}

/*-----------------
	51. Error
-----------------------*/

.error-page {
	align-items: center;
	background-color: rgba(0, 197, 251, 0.2);
	color: #1f1f1f;
	display: flex;
}

.error-page .main-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.error-box {
	margin: 0 auto;
	max-width: 480px;
	padding: 40px 0;
	text-align: center;
	width: 100%;
}

.error-box h1 {
	color: #00c5fb;
	font-size: 10em;
}

.error-box p {
	margin-bottom: 30px;
}

.error-box .btn {
	border-radius: 50px;
	font-size: 16px;
	font-weight: normal;
	min-width: 200px;
	padding: 10px 20px;
}

/*-----------------
	52. Lock Screen
-----------------------*/

.lock-user {
	margin-bottom: 20px;
	text-align: center;
}

.lock-user img {
	margin-bottom: 15px;
	width: 100px;
}

/*-----------------
	53. OTP
-----------------------*/

.otp-wrap {
	margin-bottom: 30px;
	text-align: center;
}

.otp-input {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	display: inline-block;
	font-size: 16px;
	font-weight: normal;
	height: 70px;
	line-height: 29px;
	margin-right: 15px;
	text-align: center;
	width: 70px;
}

.otp-input:last-child {
	margin-right: 0;
}

/*-----------------
	54. Salary Settings
-----------------------*/

.settings-widget+.settings-widget {
	margin-top: 30px;
}

.row.row-sm {
	margin-left: -5px;
	margin-right: -5px;
}

.row.row-sm>div {
	padding-left: 5px;
	padding-right: 5px;
}

.set-btn {
	min-height: 44px;
}

/*-----------------
	55. Components
-----------------------*/

.comp-buttons .btn {
	margin-bottom: 5px;
	margin-right: 5px;
}

.comp-buttons .btn-group .btn {
	margin-right: 0;
}

.comp-dropdowns .btn-group {
	margin-bottom: 5px;
	margin-right: 5px;
}

.progress-example .progress {
	margin-bottom: 1.5rem;
}

.progress-xs {
	height: 4px;
}

.progress-sm {
	height: 15px;
}

.progress.progress-sm {
	height: 6px;
}

.progress.progress-md {
	height: 8px;
}

.progress.progress-lg {
	height: 18px;
}

.stickyside .sidebar-menu>ul>li>a.active {
	color: #00c5fb;
}

.comp-section {
	margin-bottom: 30px;
	padding-bottom: 30px;
	padding-top: 30px;
}

.comp-section:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.comp-section .section-title {
	font-size: 1.125rem;
	margin-bottom: 20px;
	text-decoration: underline;
	text-transform: uppercase;
}

/*-----------------
	56. Search
-----------------------*/

.main-search {
	margin-bottom: 20px;
}

.search-result {
	margin-bottom: 30px;
}

.search-result u {
	color: #00b7ed;
}

.search-result p {
	color: #777;
	margin-bottom: 0;
}

.search-lists .nav-tabs.nav-tabs-solid {
	background-color: #fff;
	border: 1px solid #e3e3e3;
}

.top-nav-search form {
	margin-top: 10px;
	margin-right: 100px;
	position: relative;
	width: 513px;
}

.top-nav-search .form-control {
	background-color: #FEFEFE;
	border: 1px solid rgba(255, 255, 255, 0.15);
	border-radius: 10px;
	color: #324D65;
	right: 20px;
	height: 40px;
	padding: 10px 40px 10px 40px;
	/* margin-left: -340px; */
}

.top-nav-search .btn {
	background-color: transparent;
	border-color: transparent;
	color: #01265D;
	min-height: 40px;
	padding: 7px 15px;
	position: absolute;

}

.top-nav-search .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.responsive-search {
	display: none;
	color: #333;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
	padding: 0 15px;
}

.top-nav-search.active form {
	display: block;
	left: 0;
	position: absolute;
}

/*-----------------
	57. Knowledgebase
-----------------------*/

.topics {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
	padding: 20px;
}

.topics .topic-title {
	color: #333;
	margin-bottom: 20px;
	padding: 0 25px 0 0;
	position: relative;
}

.topics .topic-title a {
	color: #333;
	font-size: 16px;
	font-weight: normal;
	text-decoration: none;
}

/* .text-end {
	z-index: 2 !important;
	position: relative;
} */

/* tr {
	z-index: 100 !important;
	position: relative;
} */


/* .dropdown-item{
	position: absolute;
	z-index: 2;

}  */

.topics .topic-title a:hover {
	color: #00b7ed;
}

.topics .topic-title a span {
	color: #00b7ed;
}

.topics .topic-title a i {
	color: #00b7ed;
	margin-right: 5px;
}

.topics .topics-list {
	padding: 0;
	margin: 0;
}

.topics .topics-list li {
	list-style: none;
	line-height: 2;
}

.topics .topics-list li a {
	padding-left: 25px;
	color: #555;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
	outline: 0 none;
}

.topics .topics-list li a:hover {
	color: #00b7ed;
}

.topics .topics-list li:before {
	    font-family: "Open_Sans", Tofu;
	content: "\f016";
	color: #555;
	font-size: 14px;
	left: 0;
	position: absolute;
	top: 0;
}

.topics .topics-list li {
	position: relative;
}

.widget {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
	padding: 20px;
}

.widget:last-child {
	margin-bottom: 0;
}

.widget h4 {
	color: #333;
	font-size: 16px;
	font-weight: normal;
	line-height: 25px;
}

.widget>ul {
	margin-bottom: 0;
	padding: 0;
	margin: 0;
}

.widget-category li {
	line-height: 35px;
	list-style: none;
	position: relative;
}

.widget-category li:before {
	    font-family: "Open_Sans", Tofu;
	content: "\f016";
	color: #555;
	font-size: 16px;
	left: 0;
	position: absolute;
	top: 0;
}

.widget-category li a {
	color: #5e6977;
	padding-left: 25px;
}

.widget-category li a:hover {
	color: #00b7ed;
}

.post {
	margin-bottom: 50px;
}

.post .meta {
	background-color: #f9f9f9;
	border: 1px solid #efefef;
	font-size: 14px;
	margin: 0 0 20px;
	padding: 10px;
}

.post .meta p {
	padding: 15px 0;
}

.post .meta li {
	display: inline-block;
	color: #86939e;
}

.post .meta li span {
	color: #43484d;
}

.post .meta li:after {
	content: "/";
	padding: 0 10px;
}

.post .meta li:last-child:after {
	content: none;
}

.post img {
	padding: 40px 0;
	max-width: 100%;
}

.feedback {
	background-color: #f9f9f9;
	border: 1px solid #efefef;
	padding: 20px;
	margin-bottom: 50px;
}

.feedback h3 {
	color: #333;
	font-size: 16px;
	margin-bottom: 20px;
}

.feedback p:last-child {
	margin-bottom: 0;
}

.post .meta a {
	color: #00b7ed;
}

.widget .widget-title i {
	color: #00b7ed;
	font-size: 16px;
	margin-right: 10px;
}

.post h1.post-title {
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

.post h2 {
	font-size: 16px;
	font-weight: normal;
}

.post>p {
	margin-bottom: 20px;
}

.comment-section {
	float: left;
	width: 100%;
}

.comment-section h3 {
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 30px;
}

ul.comment-list {
	border-top: 0;
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

ul.comment-list li {
	border: 0;
	box-sizing: border-box;
	clear: both;
	float: left;
	list-style: outside none none;
	margin: 0 0 30px;
	padding: 0 0 0 70px;
	position: relative;
	width: 100%;
}

ul.comment-list li:last-child {
	margin-bottom: 0;
}

ul.comment-list li div.comment {
	border-bottom: 1px solid #ccc;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

ul.comment-list li .comment-author {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

ul.comment-list li .comment-author img {
	border-radius: 50px;
	float: left;
	height: 50px;
	margin: 0;
	max-height: 50px;
	max-width: 50px;
	width: 50px;
}

ul.comment-list li .comment-details {
	margin: 10px 0;
	padding: 0 0 10px;
	position: relative;
}

ul.comment-list li .author-name>a {
	color: #2f363f;
	font-size: 16px;
	font-weight: normal;
	line-height: 19px;
	text-transform: capitalize;
}

ul.comment-list li .author-name span {
	float: left;
	font-size: 14px;
	width: 100%;
}

ul.comment-list li .comment-body {
	float: left;
	margin: 10px 0 0;
	width: 100%;
}

ul.comment-list li .comment-body p {
	line-height: 28px;
}

ul.comment-list li .reply a {
	clear: both;
	color: #2f363f;
	display: inline-block;
	font-size: 14px;
	font-weight: normal;
	margin: 10px 0 0;
}

ul.comment-list li ul.children {
	border: 0 none;
	clear: both;
	float: left;
	margin: 10px 0;
	padding: 0;
	width: 100%;
}

.comment-section .comment-reply {
	float: left;
	margin-top: 30px;
	width: 100%;
}

.comment-reply form {
	float: left;
	margin-bottom: 20px;
	width: 100%;
}

.comment-reply .form-control {
	background-color: #f9f9f9;
	border: 1px solid #e7e7e7;
	border-radius: 0;
	min-height: 50px;
}

.comment-reply .form-control:focus {
	box-shadow: unset;
}

/*-----------------
	58. FAQ
-----------------------*/

.faq-card .card-header {
	background-color: #fff;
	padding: 20px;
	position: relative;
}

.faq-card .card {
	border-color: #ddd;
	border-radius: 3px;
	box-shadow: none;
	margin-bottom: 20px;
	overflow: hidden;
}

.faq-card .card .card-header h4 {
	margin-bottom: 0;
}

.faq-card .card .card-header h4>a {
	color: #090909;
	display: block;
	font-size: 16px;
	font-weight: normal;
	padding-right: 60px;
	position: relative;
}

.faq-card .card .card-header h4>a:after {
	    font-family: "Open_Sans", Tofu;
	font-size: 16px;
	position: absolute;
	right: 0px;
	font-weight: normal;
}

.faq-card .card .card-header h4>a.collapsed:after {
	content: "\f067";
}

.faq-card .card .card-header h4>a:not(.collapsed):after {
	content: "\f068";
}

.faq-card .card .card-header h4>label {
	color: #fff;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	padding-right: 60px;
	position: relative;
}

.faq-card .card .card-header h4>label:after {
	    font-family: "Open_Sans", Tofu;
	font-size: 16px;
	position: absolute;
	right: 0px;
	font-weight: normal;
	top: 0;
}

.faq-card .card .card-header h4>label.collapsed:after {
	content: "\f067";
}

.faq-card .card .card-header h4>label:not(.collapsed):after {
	content: "\f068";
}

.small-container {
	margin: auto;
	max-width: 1100px;
	padding: 30px;
}

.inner-header {
	margin-bottom: 30px;
}

/*-----------------
	59. Employee Dashboard
-----------------------*/

.welcome-box {
	background-color: #fff;
	border-bottom: 1px solid #ededed;
	display: flex;
	margin: -30px -30px 30px;
	padding: 20px;
	position: relative;
}

.welcome-img {
	margin-right: 15px;
}

.welcome-img img {
	border-radius: 8px;
	width: 60px;
}

.welcome-det p {
	color: #777;
	font-size: 16px;
	margin-bottom: 0;
}

.dash-section {
	margin-bottom: 30px;
}

.dash-list {
	background-color: white;
	border-color: #f1f6f8;
	color: #2e424d;
	display: flex;
	margin: 10px 0 0;
}

.dash-card-container {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}

.dash-card-icon {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	padding-right: 15px;
}

.dash-card-icon i {
	width: 30px;
}

.dash-card-content {
	align-items: center;
	display: flex;
	flex-direction: row;
}

.e-avatar {
	background-color: #fff;
	border: 3px solid #e7e7e7;
	border-radius: 50%;
	height: 45px;
	position: relative;
	width: 45px;
	margin-left: -20px;
}

.e-avatar:first-of-type {
	margin-left: 0 !important;
}

.dash-card-avatars {
	align-items: center;
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	padding: 0 15px;
}

.e-avatar>img {
	border-radius: 50%;
	width: 100%;
}

.dash-sec-title {
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.dash-info-list .dash-card {
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	color: #575757;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: auto;
	padding: 15px;
}

.dash-card-content p {
	font-size: 16px;
	margin-bottom: 0;
}

.dash-sec-content .dash-info-list+.dash-info-list {
	margin-top: 15px;
}

.dash-sidebar h5 {
	color: inherit;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.5;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.dash-sidebar p {
	color: #777;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.5;
	margin-bottom: 0;
	text-transform: uppercase;
}

.dash-sidebar .card {
	border-radius: 8px;
	box-shadow: unset;
}

.time-list {
	display: flex;
	flex-grow: 1;
	margin-bottom: 20px;
}

.request-btn {
	text-align: center;
}

.dash-stats-list {
	align-items: center;
	display: flex;
	flex-flow: column wrap;
	flex-grow: 1;
	padding-left: 15px;
	padding-right: 15px;
}

.dash-stats-list+.dash-stats-list {
	border-left: 1px solid #e7e7e7;
}

.dash-stats-list h4 {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.5;
	margin-bottom: 0;
}

/*-----------------
	60. Performance Review
-----------------------*/

.review-table tr {
	background-color: #fff;
}

.review-section {
	margin-bottom: 30px;
}

.review-header {
	background-color: #fff;
	border: 1px solid #dee2e6;
	margin-bottom: 0;
	padding: 15px;
	text-align: center;
}

.review-header h3 {
	font-size: 1.4rem;
	margin-bottom: 3px;
}

.review-header p {
	margin-bottom: 0;
}

.review-table .form-control {
	height: 36px;
}

.grade-span .badge {
	border-radius: 3px;
	display: inline-block;
	font-size: 14px;
	min-width: 75px;
	padding: 4px 12px;
	text-align: center;
}

.review-table .select2-container .select2-selection--single {
	height: 36px;
}

.review-table .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 34px;
}

.review-table .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 34px;
}

/*-----------------
	61. Kanban Board
-----------------------*/

.card>.task-header {
	line-height: 15px;
	display: flex;
	justify-content: space-between;
}

.task-header {
	font-size: 14px;
	font-weight: normal;
	padding: 15px 15px 5px;
}

.task-header a {
	color: #1f1f1f;
}

.task-body {
	padding: 0 15px 15px;
	font-size: 14px;
}

.kanban-list:last-child {
	margin-right: 0;
}

.kanban-list>.kanban-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.kanban-list>.kanban-header>.status-title {
	color: #fff;
	font-size: 14px;
	font-weight: normal;
}

.kanban-list>.kanban-wrap {
	padding: 20px;
}

.kanban-list>.kanban-wrap>.drag-placeholder {
	height: 146px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.05);
	margin-bottom: 12px;
}

.kanban-list>.kanban-wrap.ks-empty>.card.drop-here {
	display: block;
}

.kanban-list>.kanban-wrap>.card {
	border: 1px solid #e3e3e3;
	border-radius: 2px;
	box-shadow: unset;
	margin: 0 0 12px;
}

.kanban-list>.kanban-wrap>.card.drop-here {
	display: none;
	text-align: center;
	background: transparent;
	padding: 15px;
	border-style: dashed;
}

.kanban-list>.kanban-wrap>.card.drop-here>a {
	color: #333;
	font-weight: normal;
}

.kanban-list>.kanban-wrap>.card:last-child {
	margin-bottom: 0;
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}

.kanban-list>.kanban-wrap>.card>.task-header {
	font-size: 14px;
	font-weight: normal;
	border: none;
	padding: 15px 15px 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.kanban-list>.kanban-wrap>.card>.task-body {
	padding: 0 15px 15px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info {
	display: flex;
	align-items: center;
	font-size: 14px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress {
	margin-bottom: 0;
	margin-right: 10px;
	width: 100%;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	margin-top: 10px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer .ks-icon {
	color: #d7dceb;
	font-size: 16px;
	display: inline-block;
	margin-right: 3px;
	position: relative;
	top: 1px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer>.task-users>.task-avatar>img {
	width: 24px;
	height: 24px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer>.task-users>.ks-amount {
	font-size: 14px;
	font-weight: normal;
	display: inline-block;
	margin-left: 3px;
	position: relative;
	top: 1px;
}

.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card.drop-here {
	border-color: #f7c1b7;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: #edf7ee;
}

.kanban-list.kanban-success>.kanban-header {
	background-color: #4caf50;
}

.kanban-list.kanban-success>.kanban-wrap>.card.drop-here {
	border-color: #abebc6;
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #4caf50;
}

.kanban-list.kanban-info {
	background-color: #e7f3fe;
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-info>.kanban-wrap>.card.drop-here {
	border-color: #a6dbf9;
}

.kanban-list.kanban-warning {
	background-color: #fdfcf3;
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.drop-here {
	border: 1px solid #f2e49e;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.task-avatar {
	border-radius: 50%;
	position: relative;
}

.kanban-box.ui-sortable-handle {
	cursor: move;
}

.card>.task-board-header {
	line-height: 15px;
	display: flex;
	justify-content: space-between;
}

.task-board-header {
	font-size: 14px;
	font-weight: normal;
	padding: 15px 15px 5px;
	display: flex;
	justify-content: space-between;
}

.task-board-header a {
	color: #1f1f1f;
}

.task-board-body {
	padding: 0 15px 15px;
	font-size: 14px;
}

.progress>.progress-bar {
	background-color: #25628F;
}

.kanban-cont {
	display: flex;
	overflow: auto;
	padding-bottom: 20px;
}

.kanban-list {
	align-self: flex-start;
	margin-right: 30px;
	min-width: 300px;
	width: 300px;
}

.kanban-list:last-child {
	margin-right: 0;
}

.kanban-list>.kanban-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.kanban-list>.kanban-header>.status-title {
	color: #fff;
	font-size: 14px;
	font-weight: normal;
}

.kanban-list>.kanban-wrap {
	padding: 20px;
}

.kanban-list>.kanban-wrap>.drag-placeholder {
	height: 146px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.05);
	margin-bottom: 12px;
}

.kanban-list>.kanban-wrap>.card {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 2px;
	box-shadow: unset;
	margin: 0 0 12px;
}

.kanban-list>.kanban-wrap>.card.active {
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.kanban-list>.kanban-wrap>.card:last-child {
	margin-bottom: 0;
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}

.kanban-list>.kanban-wrap>.card>.task-board-header {
	font-size: 14px;
	font-weight: normal;
	border: none;
	padding: 15px 15px 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.kanban-list>.kanban-wrap>.card>.task-board-body {
	padding: 0 15px 15px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info {
	display: flex;
	align-items: center;
	font-size: 14px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress {
	margin-bottom: 0;
	margin-right: 10px;
	width: 100%;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	margin-top: 10px;
	align-items: flex-end;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer>.task-users>.task-avatar>img {
	width: 24px;
	height: 24px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer>.task-users>.task-user-count {
	font-size: 14px;
	font-weight: normal;
	display: inline-block;
	margin-left: 3px;
	position: relative;
	top: 1px;
}

.kanban-list.kanban-danger>.kanban-wrap>.card.active {
	border-color: #ef5350;
}

.kanban-list.kanban-success>.kanban-wrap>.card.active {
	border-color: #4caf50;
}

.kanban-list.kanban-info>.kanban-wrap>.card.active {
	border-color: #42a5f5;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.active {
	border-color: #ffb300;
}

.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: #edf7ee;
}

.kanban-list.kanban-success>.kanban-header {
	background-color: #4caf50;
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #4caf50;
}

.kanban-list.kanban-info {
	background-color: #e7f3fe;
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-warning {
	background-color: #fdfcf3;
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.kanban-list.kanban-purple {
	background-color: #f1effd;
}

.kanban-list.kanban-purple>.kanban-header {
	background-color: #7460ee;
}

.kanban-list.kanban-purple>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #7460ee;
}

.kanban-list.kanban-primary {
	background-color: #fff5ec;
}

.kanban-list.kanban-primary>.kanban-header {
	background-color: #ff9b44;
}

.kanban-list.kanban-primary>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ff9b44;
}

.task-avatar {
	border-radius: 50%;
	position: relative;
}

.kanban-box.ui-sortable-handle {
	cursor: move;
}

.kanban-action>a {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.3);
	display: inline-flex;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	justify-content: center;
}

.task-board-color .board-color-list {
	display: flex;
	height: 45px;
}

.task-board-color .board-color-list .board-control {
	position: relative;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	min-height: 1.5rem;
	padding-left: 1.5rem;
	margin-right: 1rem;
	cursor: pointer;
	padding-left: 0;
	cursor: pointer;
	width: 36px;
	height: 36px;
}

.task-board-color .board-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.board-control>.board-control-input:checked~.board-indicator:before {
	content: "\f00c";
	display: block;
	width: 16px;
	height: 16px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	color: #fff;
	border: none;
	position: relative;
	    font-family: "Open_Sans", Tofu;
	text-align: center;
	line-height: 16px;
}

.board-control>.board-control-input:checked~.board-indicator::before {
	width: 36px;
	height: 36px;
	line-height: 36px;
}

.board-control>.board-indicator {
	width: 36px;
	height: 36px;
}

.board-control.board-primary>.board-indicator {
	background: #ff9b44;
}

.board-control.board-success>.board-indicator {
	background: #4caf50;
}

.board-control.board-info>.board-indicator {
	background: #42a5f5;
}

.board-control.board-purple>.board-indicator {
	background: #7460ee;
}

.board-control.board-warning>.board-indicator {
	background: #ffb300;
}

.board-control.board-danger>.board-indicator {
	background: #ef5350;
}

.kanban-board.task-wrapper .kanban-list {
	width: 100%;
}

.board-view-header {
	margin-bottom: 30px;
}

.pro-teams {
	display: inline-flex;
	margin-right: 15px;
	text-align: center;
	margin-bottom: 20px;
}

.pro-teams .pro-team-members {
	margin-left: 15px;
	display: flex;
	align-items: center;
}

.pro-teams h4 {
	margin-bottom: 0;
	margin-right: 6px;
	font-size: 14px;
}

.pro-team-lead {
	display: flex;
	align-items: center;
}

.board-view-header .view-icons {
	float: right;
	margin-right: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 2px 7px;
	border-radius: 4px;
}

.board-view-header .view-icons .btn {
	margin: 0;
	padding: 0;
}

.board-view-header .view-icons a.btn+a.btn {
	margin-left: 5px;
}

.pro-progress-bar {
	display: flex;
	align-items: center;
}

.pro-progress-bar h4 {
	margin-bottom: 0;
	margin-right: 10px;
	font-size: 14px;
	text-transform: uppercase;
}

.pro-progress-bar .progress {
	width: 100%;
	margin-bottom: 0;
	margin-right: 10px;
	height: 15px;
	background-color: #e3e3e3;
	box-shadow: unset;
}

.pro-progress-bar .progress>.progress-bar {
	background-color: #ff9b44;
}

.pro-teams .avatar-group {
	display: inline-flex;
}

.pro-teams .avatar-group .avatar {
	margin-right: 0;
	position: relative;
	display: inline-block;
	width: 34px;
	height: 34px;
	background-color: transparent;
}

.pro-teams .avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.pro-teams .avatar-group .avatar .border {
	border: 3px solid #fff !important;
}

.pro-teams .avatar-group .avatar>img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}

.border-white {
	border-color: #fff !important;
}

.pro-teams .avatar-group .avatar:hover {
	z-index: 1;
}

.pro-teams .avatar .avatar-title {
	font-size: 14px;
	border-radius: 50%;
}

.pro-teams .avatar-title {
	width: 100%;
	height: 100%;
	background-color: #ff9b44;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.task-date {
	display: block;
}

.task-priority {
	padding: .3em .6em .3em;
}

.task-follower-list {
	display: flex;
	margin-top: 15px;
}

.task-follower-list span {
	position: relative;
}

.task-follower-list span i {
	cursor: pointer;
	position: absolute;
	top: -5px;
	right: 5px;
	color: #f00;
}

.add-new-task {
	text-align: center;
	padding: 0 20px 20px;
}

.add-new-task a {
	color: #333;
	font-size: 14px;
}

.project-add-form {
	display: none;
	margin-top: 10px;
}

.project-add-form.opened {
	display: block;
}

#myOverviewDiv {
	position: absolute;
	width: 200px;
	height: 100px;
	top: 10px;
	left: 10px;
	background-color: #f2f2f2;
	z-index: 300;
	/* make sure its in front */
	border: solid 1px #7986cb;
}

/*-----------------
	62. File Manager
-----------------------*/
.wday-box input {
	opacity: 0;
}

.wday-box .checkmark {
	border: 2px solid #ff9b44;
	width: 40px;
	height: 40px;
	line-height: 35px;
	padding-left: 13px !important;
	color: #ff9b44;
	display: block;
}

.wday-box input:checked~.checkmark {
	background: #ff9b44;
	color: #fff;
}

.wday-box .checkbox-inline {
	margin-right: 5px;
}

.table-responsive {
	/* width: 100px; */
	overflow-x: scroll;
	margin-left: 0px;

}

/* .sticky-col{
	position: -webkit-sticky;
	position: sticky;
	background-color: white;
}
.first-col{
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	left: 0px;
}
.second-col{
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	left: 100px;
}  */
.user-add-shedule-list a {
	display: inline-block;
	padding: 10px;
	border: 1px dashed #a7a7a7;
	color: #a7a7a7;
	border-radius: 5px;
}

.user-add-shedule-list h2 a {
	border: 2px dashed #1EB53A;
	padding: 10px;
	color: #1EB53A;
}

.user-add-shedule-list span {
	justify-content: center;
	display: flex;
}

.file-wrap {
	border: 1px solid #e0e3e4;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.file-wrap .file-sidebar {
	border-right: 1px solid #e0e3e4;
	left: 0;
	position: absolute;
	z-index: 5;
	width: 300px;
	transition: 0.3s ease-in-out;
}

.file-wrap .file-sidebar .file-header {
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e0e3e4;
	color: #324148;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-sidebar .file-header span {
	font-size: 16px;
	font-weight: normal;
	text-transform: capitalize;
}

.file-wrap .file-sidebar .file-search {
	background-color: #f5f5f6;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 15px;
	width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group {
	width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group .form-control {
	background-color: #fff;
	border-radius: 50px;
	padding-left: 36px;
}

.file-wrap .file-sidebar .file-search .input-group .form-control:focus {
	border-color: #ccc;
	box-shadow: none;
}

.file-wrap .file-sidebar .file-search .input-group .input-group-prepend {
	align-items: center;
	bottom: 0;
	color: #666;
	display: flex;
	left: 15px;
	pointer-events: none;
	position: absolute;
	top: 0;
	z-index: 4;
}

.file-wrap .file-scroll {
	max-height: calc(100vh - 259px);
	min-height: 497px;
	overflow-y: auto;
}

.file-wrap .file-sidebar .file-pro-list {
	background-color: #fff;
	padding: 15px;
}

.file-side-close {
	display: none;
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	color: #bbb;
	font-size: 14px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
	width: 26px;
	position: absolute;
	right: 15px;
}

.file-wrap .file-content {
	flex: 0 0 100%;
	max-width: 100%;
}

.file-wrap .file-content .file-header {
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e0e3e4;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-content .file-body {
	background-color: #f5f5f6;
}

.dropdown-file {
	position: absolute;
	top: 10px;
	right: 10px;
}

.dropdown-file .dropdown-link {
	color: #777;
	width: 24px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.dropdown-file .dropdown-link:hover,
.dropdown-file .dropdown-link:focus {
	color: #1b2e4b;
}

.dropdown-file .dropdown-item {
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
}

.card-file {
	padding: 10px;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.card-file:hover,
.card-file:focus {
	border-color: #ccc;
}

.card-file .card-body {
	padding: 15px 0 0;
}

.card-file .card-footer {
	font-size: 14px;
	color: #a9a9a9;
	padding: 10px 0 0;
	border-top-width: 0;
	background-color: transparent;
}

.card-file h6 {
	margin-bottom: 0;
	font-size: 0.875rem;
}

.card-file h6 a {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
	color: #333;
}

.card-file span {
	font-size: 14px;
	color: #888;
}

.card-file-thumb {
	align-items: center;
	background-color: #f5f5f5;
	color: #777;
	display: flex;
	font-size: 48px;
	height: 120px;
	justify-content: center;
}

.file-wrap .file-content .file-body .file-content-inner {
	padding: 15px;
	width: 100%;
}

.file-wrap.file-sidebar-toggle .file-sidebar {
	left: -331px;
}

.file-wrap.file-sidebar-toggle .file-cont-wrap {
	margin-left: 0;
}

.file-wrap .file-cont-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-left: 300px;
	transition: 0.4s ease-in-out;
}

.file-wrap .file-cont-wrap .file-cont-inner {
	flex: 0 0 100%;
	max-width: 100%;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header {
	align-items: center;
	background: #fff;
	border-bottom: 1px solid #e0e3e4;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header>span {
	color: #333;
	font-size: 16px;
	font-weight: normal;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-sidebar-toggle {
	color: #333;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options {
	display: flex;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>a,
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>span {
	color: #333;
	display: inline-block;
	font-size: 16px;
	margin-left: 25px;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>a:first-child,
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>span:first-child {
	margin-left: 0;
}

.file-wrap .file-content .file-search {
	background-color: #f5f5f6;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 15px;
	width: 100%;
}

.file-wrap .file-content .file-search .input-group {
	width: 100%;
}

.file-wrap .file-content .file-search .input-group .form-control {
	background-color: #fff;
	border-radius: 50px;
	padding-left: 36px;
}

.file-wrap .file-content .file-search .input-group .form-control:focus {
	border-color: #ccc;
	box-shadow: none;
}

.file-wrap .file-content .file-search .input-group .input-group-prepend {
	align-items: center;
	bottom: 0;
	color: #666;
	display: flex;
	left: 15px;
	pointer-events: none;
	position: absolute;
	top: 0;
	z-index: 4;
}

.btn-file {
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
}

.btn-file input {
	cursor: pointer;
	direction: ltr;
	filter: alpha(opacity=0);
	font-size: 16px;
	height: 100%;
	margin: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.file-menu {
	display: inline-block;
	margin: 0 0 10px;
	padding: 0;
	width: 100%;
}

.file-menu li {
	display: inline-block;
	width: 100%;
}

.file-menu li+li {
	margin-top: 2px;
}

.file-menu li a {
	color: #333;
	display: inline-block;
	padding: 10px 15px;
	width: 100%;
	text-transform: capitalize;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	transition: 0.3s ease;
}

.file-menu li a i {
	font-size: 14px;
	padding-right: 10px;
	color: #878787;
}

.file-menu li a:hover,
.file-menu li.active a,
.file-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}

.show-more {
	text-align: center;
}

.show-more a {
	position: relative;
	padding: 0 20px 0 15px;
}

.show-more a:before {
	content: '\f107';
	top: 50%;
	right: 0;
	position: absolute;
	font-size: 16px;
	line-height: inherit;
	    font-family: "Open_Sans", Tofu;
	transform: translateY(-50%);
}

/*-----------------
	63. Subscriptions
-----------------------*/

.pricing-box {
	text-align: center;
}

.pricing-box ul {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;
}

.pricing-box ul li {
	line-height: 35px;
}

.pricing-box ul li i {
	color: #00bf6f;
	margin-right: 5px;
}

.equal-height-cards>div>div.card {
	height: 100%;
}

.mb-30 {
	margin-bottom: 30px !important;
}

/*-----------------
	64. Responsive
-----------------------*/

@media only screen and (min-width: 991px) {
	#toggle_btn {
		color: #999;
		float: left;
		font-size: 14px;
		/*line-height: 60px;*/
		padding: 0 10px;
	}

	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 30px;
		padding-top:2px;
		width: auto;
	}

	.mini-sidebar .header .header-left {
		padding: 0 5px;
		width: 60px;
		/*margin-right:70px;*/
	}
	/*.mini-sidebar  .headerRight {*/
		/*float: left;*/
		/*width: 95%;*/
		/*padding-top: 10px;*/
		/*padding-left: 20px;*/
		/*padding-bottom: 10px;*/
	/*}*/

	.mini-sidebar .sidebar {
		width: 60px;
	}
	/*.mini-sidebar .headerNavFloatRight {*/
		/*float: right;*/
		/*margin-top: -8px;*/
		/*margin-left: auto;*/
		/*width: 32%;*/
	/*}*/

	.mini-sidebar.expand-menu .sidebar {
		width: 210px;
	}
	.mini-sidebar.expand-menu .header-left {
		width: 210px;
	}

	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}

	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}

	.mini-sidebar .menu-title a {
		visibility: hidden;
	}

	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}

	.mini-sidebar .sidebar .noti-dot:before {
		display: none;
	}

	.mini-sidebar .sidebar .noti-dot:after {
		display: none;
	}

	.mini-sidebar.expand-menu .sidebar .noti-dot:before {
		display: block;
	}

	.mini-sidebar.expand-menu .sidebar .noti-dot:after {
		display: block;
	}

	.modal-open.mini-sidebar .sidebar {
		z-index: 1001;
	}

	.mini-sidebar .sidebar .sidebar-menu ul>li>a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}

	.mini-sidebar .sidebar .sidebar-menu ul>li>a span.chat-avatar-sm {
		display: inline-block;
		opacity: 1;
	}

	.mini-sidebar .sidebar .sidebar-menu ul>li>a span.status {
		display: inline-block;
		opacity: 1;
	}

	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
		display: inline;
		opacity: 1;
	}

	.mini-sidebar .sidebar .sidebar-menu>ul>li>a i {
		font-size: 16px;
		width: 30px;
	}

	.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li>a i {
		font-size: 16px;
		width: 20px;
	}

	.mini-sidebar .page-wrapper {
		margin-left: 60px;
	}
}

@media only screen and (min-width: 768px) {
	.modal-md {
		width: 600px;
	}

	.nav-tabs.nav-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs.nav-justified.nav-tabs-top>li>a,
	.nav-tabs.nav-justified.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-justified.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}

	.nav-tabs.nav-tabs-top>li {
		margin-bottom: 0;
	}

	.nav-tabs.nav-tabs-top>li>a,
	.nav-tabs.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}

	.nav-tabs.nav-tabs-top>li.open>a,
	.nav-tabs.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-tabs-top>li>a:focus {
		border-top-color: #ddd;
	}

	.nav-tabs.nav-tabs-top>li+li>a {
		margin-left: 1px;
	}

	.nav-tabs.nav-tabs-top>li>a.active,
	.nav-tabs.nav-tabs-top>li>a.active:hover,
	.nav-tabs.nav-tabs-top>li>a.active:focus {
		border-top-color: #00c5fb;
	}

	.nav-tabs.nav-tabs-bottom>li {
		margin-bottom: -1px;
	}

	.nav-tabs.nav-tabs-bottom>li>a.active,
	.nav-tabs.nav-tabs-bottom>li>a.active:hover,
	.nav-tabs.nav-tabs-bottom>li>a.active:focus {
		border-bottom-width: 2px;
		border-color: transparent;
		border-bottom-color: #00c5fb;
		background-color: transparent;
		transition: none 0s ease 0s;
		-moz-transition: none 0s ease 0s;
		-o-transition: none 0s ease 0s;
		-ms-transition: none 0s ease 0s;
		-webkit-transition: none 0s ease 0s;
	}

	.nav-tabs.nav-tabs-solid {
		background-color: #fafafa;
		border: 0;
	}

	.nav-tabs.nav-tabs-solid>li {
		margin-bottom: 0;
	}

	.nav-tabs.nav-tabs-solid>li>a {
		border-color: transparent;
	}

	.nav-tabs.nav-tabs-solid>li>a:hover,
	.nav-tabs.nav-tabs-solid>li>a:focus {
		background-color: #f5f5f5;
	}

	.nav-tabs.nav-tabs-solid>.open:not(.active)>a {
		background-color: #f5f5f5;
		border-color: transparent;
	}

	.nav-tabs-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs-justified.nav-tabs-top>li>a,
	.nav-tabs-justified.nav-tabs-top>li>a:hover,
	.nav-tabs-justified.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}
}

@media only screen and (max-width: 1199.98px) {
	.page-title-box {
		display: none;
	}

	.file-wrap .file-sidebar {
		left: -331px;
	}

	.file-wrap .file-cont-wrap {
		margin-left: 0;
	}

	.file-wrap.file-sidebar-toggle .file-sidebar {
		left: 0;
	}

	.file-wrap.file-sidebar-toggle .file-cont-wrap {
		margin-left: 0;
	}

	.file-side-close {
		display: inline-block;
	}
}

@media only screen and (max-width: 991.98px) {
	.profile-rightbar {
		display: inline-block !important;
	}

	.profile-info-left {
		border-right: none;
		border-bottom: 2px dashed #ccc;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	.task-right-sidebar .task-chat-view {
		display: block;
		position: fixed;
		right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		margin-right: 0;
	}

	.task-chat-view {
		display: none;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		right: 0;
		transform: translateX(0px);
		z-index: 1041;
		width: 300px;
		position: fixed;
		transition: all 0.4s ease 0s;
		margin-right: -300px;
		display: table-cell;
		top: 0;
		padding-bottom: 60px;
		margin-top: 60px;
	}

	.chat-profile-view {
		display: none;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		right: 0;
		transform: translateX(0px);
		z-index: 1041;
		width: 300px;
		position: fixed;
		transition: all 0.4s ease 0s;
		margin-right: -300px;
		display: table-cell;
		top: 0;
		padding-bottom: 60px;
		margin-top: 60px;
	}

	.message-view.task-view {
		width: 100%;
	}

	.float-start.ticket-view-details {
		width: 90%;
	}

	.chat-main-row {
		left: 0;
	}

	.header .header-left {
		position: absolute;
		width: 100%;
	}

	.mobile_btn {
		display: inline-block;
	}

	.page-title-box {
		display: none;
	}

	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}

	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}

	a.mobile_btn {
		color: #fff;
		font-size: 14px;
		/*height: 60px;*/
		left: 0;
		/*line-height: 60px;*/
		/*padding: 0 20px;*/
		position: absolute;
		top: 0;
		width: 20px;
		z-index: 10;
	}

	#toggle_btn {
		display: none;
	}

	.page-wrapper {
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}

	.responsive-search {
		color: #fff;
		display: inline-block;
	}

	.top-nav-search form {
		display: none;
	}

	.top-nav-search .form-control {
		background-color: #999;
		border-color: #999;
		color: #999;
	}

	.top-nav-search .btn {
		color: #999;
	}

	.top-nav-search .form-control::-webkit-input-placeholder {
		color: #999;
	}

	.top-nav-search .form-control::-moz-placeholder {
		color: #999;
	}

	.top-nav-search .form-control:-ms-input-placeholder {
		color: #999;
	}

	.top-nav-search .form-control::-ms-input-placeholder {
		color: #999;
	}

	.top-nav-search .form-control::placeholder {
		color: #999;
	}

	.header .has-arrow .dropdown-toggle>span:nth-child(2) {
		display: none;
	}

	.header .has-arrow .dropdown-toggle::after {
		display: none;
	}
}

@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.875rem;
	}

	h1,
	.h1 {
		font-size: 2rem;
	}

	h2,
	.h2 {
		font-size: 1.75rem;
	}

	h3,
	.h3 {
		font-size: 1.375rem;
	}

	h4,
	.h4 {
		font-size: 1rem;
	}

	h5,
	.h5 {
		font-size: 0.9375rem;
	}

	h6,
	.h6 {
		font-size: 0.75rem;
	}

	.page-title,
	.dash-widget-info>h3,
	.account-title {
		font-size: 1.5rem;

	}


	.dash-card-content p,
	.stats-info h4,
	.account-box .account-btn,
	.job-title {
		font-size: 1.25rem;
	}

	.card-title,
	.dash-stats-list h4,
	.custom-modal .modal-title,
	.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header>span,
	.faq-card .card .card-header h4>a,
	.lead {
		font-size: 1.125rem;
	}

	.btn,
	.welcome-det p,
	.kanban-list>.kanban-header>.status-title,
	.account-box label,
	.job-info .job-dept,
	.form-focus .focus-label {
		font-size: 0.9375rem;
	}

	.onoffswitch-inner::before,
	.onoffswitch-inner::after,
	.form-control,
	.user-menu.nav>li>a,
	.page-header .breadcrumb {
		font-size: 0.875rem;
	}

	.btn-group-sm>.btn,
	.btn-sm {
		font-size: 0.8125rem;
	}

	.comp-section .section-title,
	.voice-call-avatar .username,
	.page-menu .nav-tabs>li>a,
	.btn-group-lg>.btn,
	.btn-lg,
	.account-subtitle,
	.job-list h3.job-list-title {
		font-size: 1rem;
	}

	.message-content {
		font-size: 0.75rem;
	}

	.user-menu.nav>li>a {
		padding: 0 10px;
	}

	.top-nav-search form {
		width: 210px;
	}

	.profile-info-left {
		border-right: none;
		border-bottom: 2px dashed #ccc;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}

	.notifications {
		right: -48px;
	}

	.notifications::before,
	.notifications::after {
		right: 60px;
	}

	.header {
		z-index: 1040;
	}

	.task-wrapper #task-list li .task-container .task-label {
		padding-right: 50px;
		word-break: unset;
	}

	.task-btn-right {
		position: absolute;
		right: 8px;
	}

	.task-wrapper #task-list li .task-container {
		padding: 8px 10px;
	}

	.task-action-btn.task-check {
		width: 36px;
	}

	.roles-menu {
		margin-bottom: 20px;
	}

	.contacts-list {
		padding: 0 70px 0 15px;
	}

	.left-action {
		text-align: center;
		margin-bottom: 15px;
	}

	.right-action {
		text-align: center;
	}

	.top-action-left .float-start {
		float: none !important;
	}

	.top-action-left .btn-group {
		margin-bottom: 15px;
	}

	.top-action-right {
		text-align: center;
	}

	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}

	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}

	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs.nav-justified>li>a.active,
	.nav-tabs.nav-justified>li>a.active:hover,
	.nav-tabs.nav-justified>li>a.active:focus {
		border-color: transparent transparent transparent #00c5fb;
		border-left-width: 2px;
	}

	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}

	.nav-item {
		width: 300px;
	}

	.nav-tabs .nav-item {
		margin-bottom: 0;
	}

	.nav-tabs>li>a {
		border-width: 2px;
		border-left-color: transparent;

	}

	.nav-tabs .nav-link {
		border-width: 2px;
	}

	.nav-tabs>li>a:hover,
	.nav-tabs>li>a:focus {
		background-color: #fafafa;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs>li>a.active,
	.nav-tabs>li>a.active:hover,
	.nav-tabs>li>a.active:focus {
		background-color: #f5f5f5;
		border-color: transparent transparent transparent #00c5fb;
		border-left-width: 2px;
	}

	.nav-tabs>li.open:not(.active)>a,
	.nav-tabs>li.open:not(.active)>a:hover,
	.nav-tabs>li.open:not(.active)>a:focus {
		background-color: #fafafa;
	}

	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}

	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}

	.nav-tabs.nav-tabs-solid>li>a {
		border-left-width: 0 !important;
	}

	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs-justified>li>a.active,
	.nav-tabs-justified>li>a.active:hover,
	.nav-tabs-justified>li>a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #00c5fb;
	}

	.my-video ul li {
		width: 80px;
	}

	.voice-call-avatar .call-avatar {
		width: 120px;
		height: 120px;
	}

	.product-info {
		margin: 20px 0 30px;
	}

	.profile-bg {
		height: 280px;
	}

	.flag-nav>a>span {
		display: none;
	}

	.equal-height-cards>div>div.card {
		height: unset;
	}
}

@media only screen and (max-width: 575.98px) {
	.contact-cat {
		padding-right: 70px;
	}

	.user-menu {
		display: none;
	}

	.mobile-user-menu {
		display: block;
	}

	.search-box {
		display: none;
	}

	.filter-row {
		margin-bottom: 15px;
	}

	.page-wrapper>.content {
		padding: 15px;
	}

	.profile-view .profile-basic {
		margin-left: 0;
		padding-right: 0;
	}

	.profile-img-wrap {
		position: relative;
		margin: 0 auto;
	}

	.profile-info-left {
		text-align: center;
	}

	.profile-basic {
		margin-left: 0;
		margin-top: 15px;
	}

	.page-title {
		font-size: 16px;
	}

	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: 200px;
		clear: both;
	}

	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}

	.upload-text {
		font-size: 16px;
	}

	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}

	.end-call {
		margin-top: 10px;
		position: inherit;
	}

	.chat-panel .chats {
		padding: 15px 0;
	}

	.chat-right .chat-content {
		max-width: 90%;
	}

	.chat-right .chat-body {
		padding-left: 0;
		padding-right: 0;
	}

	.chat-left .chat-content {
		max-width: 90%;
	}

	.chat-left .chat-body {
		padding-left: 0;
		padding-right: 0;
	}

	.chat-avatar .avatar {
		height: 20px;
		line-height: 20px;
		width: 20px;
	}

	.account-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.float-start.ticket-view-details {
		width: 80%;
	}

	.custom-menu>li>a {
		margin-left: 10px;
	}

	.contact-alphapets {
		top: 113px;
	}

	.account-page .main-wrapper {
		flex-direction: column;
	}

	.otp-input {
		font-size: 16px;
		height: 50px;
		margin-right: 10px;
		width: 52px;
	}

	.account-wrapper {
		padding: 15px;
	}

	.leave-right {
		flex: 0 0 auto;
	}

	.pagination-lg .page-link {
		font-size: 1.2rem;
		padding: 0.5rem 0.85rem;
	}

	.call-box .call-avatar {
		height: 100px;
		max-width: 100px;
		min-width: 100px;
	}

	.call-box .call-wrapper .call-items .call-item {
		padding: 10px;
	}

	.call-box .call-wrapper .call-items .call-item.call-end {
		margin: 30px 5px 0;
		padding: 10px;
	}

	.submit-section .submit-btn {
		margin: 0 0 10px !important;
	}

	.leave-row {
		display: block;
	}

	.leave-box .subtitle {
		display: none;
	}

	.leave-right {
		margin-top: 20px;
	}

	.leave-inline-form {
		display: block;
	}

	.page-header .breadcrumb {
		display: none;
	}

	.add-btn {
		font-size: 14px;
		min-width: inherit;
	}
}

/*Project Board*/
.page-header .page-title i {
	color: #e6e9ef;
}

.page-header .page-title i:hover,
.page-header .page-title i:active {
	color: #ffcb00;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus {
	border: 1px dashed #e6e9ef;
	outline: none;
	/*  padding:5px;*/
}

.page-header .text-truncate {
	width: 330px;
}

.avatar-group {
	display: inline-flex;
}

.avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.avatar-group .avatar-xs+.avatar-xs {
	margin-left: -1.25rem;
}

.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -1.75rem;
}

.avatar-group .avatar-lg+.avatar-lg {
	margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
	margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
	z-index: 1;
}

.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #2962ff;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.error-wrapper {
	background-color: #fff;
	margin: 0;
	color: #4F5155;
	-moz-box-align: center;
	-moz-box-pack: center;
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
}

/* .body{
background-image: url("JPEG image .jpg");
  background-color: lightgray;
} */



/* #table_res table tbody tr td:nth-child(-n+2) {
	position: sticky;
	left: 0;
	z-index: 1;
	background-color: #FFF;
}

#table_res table {
	border-collapse: collapse;
	width: 100%;
	overflow-x: scroll;
}

#table_res table tbody tr {
	display: flex;
}

#table_res table tbody tr td {
	flex: 1;
	border: 1px solid #000;
} */


/*****New Design Updates***********/
.user-menu{
	width: 95%;
}
.header .header-left{
	/*width: 252px;*/
	float: left;
}
.headerRight{
	float: left;
	width: calc(100% - 210px);
	padding-top: 10px;
	padding-left: 20px;
	padding-bottom: 10px;
}
.top-nav-search .form-control{
	height: 25px;

}
.top-nav-search .headerSearch{
	width:60%;

}.top-nav-search form{
	margin-top: 0;

}
.top-nav-search .btn{
	min-height: 25px;
	padding: 0px 10px;
	position: absolute;
	top: 0;
	left: 0px;
	border-right: 1px solid #B9BCCA;
	border-radius: 0;
}
.headerNavFloatRight {
	float: right;
	margin-top: -8px;
	margin-left: auto;
	width: 36%;
}
.headerNavFloatRightItems{
	float: left;
	padding-right: 20px;
	top: 4px;
}
.round--bluebg-icon{
	background: #1698E4;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	opacity: 0.99;
	background-position: center center;
	background-repeat: no-repeat;
}
.notIcon{
	background-image: url(assets/img/notification.png);
}
.msgIcon{
	background-image: url(assets/img/msg.png);
}
.logoutIcon{
	background-image: url(assets/img/logout.png);
	top:4px;
}
/* .round--bluebg-icon .badge.rounded-pill{
	background-color: #FF9A4E;
	position: absolute;
	top: -4px;
	width: 15px;
	height: 15px;
	font-size: 14px;
	font-weight: normal;
	border-radius: 50% !important;
	padding: 0;
	line-height: 14px;
	text-align: center;
} */
.labelTxt{
	    font-family: "Open_Sans", Tofu;
	font-weight: normal;
	font-size: 14px;
	color: #FEFEFE;
	line-height: 22px;
}
.valueTxt{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #FEFEFE;
	line-height: 18px;
}
.sidebar{
	/*width: 252px;*/
	width: 210px;
	padding-bottom: 110px;
}
.userInfo{
	float: left;
	width: 100%;
  color: #fff;
  background-color: #3e75b3;
	padding: 10px;
}
.userInfoDetailsWrap{
	float: left;
	width: 100%;
  color: #fff;
  background-color: #3e75b3;
	display: flex;
	align-items: center;
}
.userProfileImg{
	width: 36px;
	height: 36px;
	margin-right: 10px;
}
.userProfileImg img{
	max-width: 100%;
	width: 100%;
}
.bludeGradBtn{
	background: linear-gradient(106.45deg, #00A3D1 5.4%, #006AC6 88.22%);
	cursor: pointer;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	margin-top: 10px;
	float: left;
	width: 100%;
	padding: 5px 10px;
	border-radius: 20px;
	color: #FFF;
}
.arrowLink{
	position: relative;
}
.arrowLink::after{
	background-image: url("assets/img/link-arrow.png");
	background-repeat: no-repeat;
	background-position: center;
	content: ' ';
	position: absolute;
	right: 5px;
	top: 6px;
	width: 20px;
	height: 20px;
}

.menu-title{
	position: relative;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: black;
	opacity: 0.8;
	padding-left: 0px;
	border-radius: 5px;
	cursor: pointer;
}
.menu-title::before{
	/*background-image: url("assets/img/support_menu.png");*/
	background-repeat: no-repeat;
	background-position: center;
	content: ' ';
	position: absolute;
	left: 5px;
	top: 6px;
	width: 20px;
	height: 20px;
}
.menu-title.Dashboard::before{
	background-image: url("assets/img/dashboard.png");
}
.menu-title.hasSubmenu::after{
	background-image: url("assets/img/menu-dropdown.png");
	background-repeat: no-repeat;
	background-position: center;
	content: ' ';
	position: absolute;
	right: 25px;
	top: 6px;
	width: 20px;
	height: 20px;
}
.menu-title.hasSubmenu.active::after{
	background-image: url("assets/img/menu-close.png");
}
.sidebar-inner{
	float: left;
	width: 100%;
	padding-left: 5px;
}
.submenuItems{
	display: none;
	position: relative;
}
.submenuItems.active{
	display: block;
}
.submenuItems,.submenuItems a,.sidebar-menu li.submenuItems a{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #FEFEFE;
	opacity: 0.8;
	list-style: none;
}
.sidebar-menu li.submenuItems a{
	padding-left: 35px;
}
.sidebar-menu {
	padding: 5px 0;
}
.hasSubmenu.active{
	background-color: #163D78;

}
.submenuItems li{
	padding-top: 10px;
}
.menuBorder{
	width: 1px;
	height: calc(100% + 10px);
	position: absolute;
	left: 15px;
	top: 4px;
	background-color: #153D78;
}
.page-title{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;
	/* color: #323765; */
	text-transform: capitalize;
	position: relative;
	margin-left:10px;
	float: left;
}
.page-title::before{
	background-repeat: no-repeat;
	background-position: center;
	width: 26px;
	height: 15px;
	position: absolute;
	left: 0;
	top: 5px;
	content: ' ';
	cursor: pointer;
}
.list-search{
	float: left;
    position: relative;
    margin-left: 10px;
	max-width: 200px;
}
.top-nav-search.list-search .form-control{
	padding: 10px;
}
.posRel{
	position: relative;
}
.top-nav-search.list-search .form-control{
	height: 30px;
	border: 1px solid #d1d3d5;
}
.top-nav-search.list-search .btn{
    min-height: 30px;
    right: 0 !important;
    left: auto;
    border-right: 0;
    border-left: 1px solid #B9BCCA;
}
.list-search-dropdown{
	max-width: 160px;
    float: left;
    margin-left: 10px;
}
.disableBackArrow.page-title::before{
	display: none;
}
.disableBackArrow.page-title{
	padding-left: 15px;
}
.breadcrumb{
	display: none;
}
.content.container-fluid{
	padding-top: 10px;
	padding-bottom: 10px;
	/*min-height: 400px;*/
}
.page-header{
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #D9D9D9;
}
.add-btn{
	background-color: #fff;
	font-family: 'Open_Sans';
	font-style: normal;
	font-weight: normal;
	margin-bottom: 7px;
	margin-right:7px;
	font-size: 14px;
	color: black;
	border: 0;
	margin-left: 10px;
	/*box-shadow: 0 8px 16px 0 rgba(57, 92, 241, 0.19), 0 6px 20px 0 rgba(57, 92, 241, 0.19);*/
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5);

}

.grid-table {
	padding-top: 0;
	margin-top: 0;
	padding-bottom: 20px;
	position: relative;
}
#datatable_length{
	display: none;
}
.action-icon{
	color: #0389CC;
}
#datatable_filter input{
	background-image: url(assets/img/search_blue.png);
	background-repeat: no-repeat;
	background-position: 6px center;
	background-size: 16px;
	padding-left: 26px;
	background-color: #FFFFFF;
	border: 1px solid #D9D9D9;
	border-radius: 4px;
	color: #555E69;
	font-family: 'Open_Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.pagination>li>a{
	border: 0;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	font-family: 'Open_Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height:22px;
	padding: 0;
	text-align: center;
}
.page-item{
	padding-left: 5px;
	padding-right: 5px;
}
.page-item.active .page-link,.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
	background-color: #555E69;
	color: #FFF;
	border: 0;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height:22px;
	padding: 0;
	text-align: center;
}
.p-link{
  color: black !important;
  background-color: #f8f9fa !important;
}
.p-datatable .p-datatable-thead > tr > th {
  color: black !important;
  background-color: #F7F7F7 !important;
	border:0.3px solid rgba(0, 0, 0, 0.07) !important;
	padding: 0.3rem 1rem !important;


}
.p-datatable .p-datatable-header{
	background-color: #fff !important;
}
.p-datatable .p-datatable-tbody > tr > td {

	border:0.3px solid rgba(0, 0, 0, 0.07) !important;

}
#datatable_info{
	display: none;
}
.p-datatable{
	box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);
}
.table.custom-table  th{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.table.custom-table>tbody>tr>td, .table.custom-table>tbody>tr>th, .table.custom-table>tfoot>tr>td, .table.custom-table>tfoot>tr>th, .table.custom-table>thead>tr>td, .table.custom-table>thead>tr>th{
	padding: 5px 10px !important;
}
.table.custom-table>tbody>tr>td{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #2B384A;
}
.table.custom-table>tbody>tr>td:first-child,table.table td:first-child h2 a{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #2B384A;
}
.table.custom-table>tbody>tr>td.fixed:first-child,.table.custom-table>thead>tr>th.fixed:first-child{
	width:50px;
}
.table.custom-table>thead>tr>th.fixed:nth-child(2), .table.custom-table>tbody>tr>td.fixed:nth-child(2){
	left:50px;
}
.table.custom-table>thead>tr>th:first-child,.table.custom-table>thead>tr>th:nth-child(2){
	z-index: 2;
}
.custom-table-wrapper #datatable_wrapper .row:nth-child(2){
	max-height:400px;
}
.table.custom-table td,.table.custom-table th{
	text-wrap: nowrap;
}
.table-action-link::after{
	display: none;
}
.table-action-link{
	color: #555E69;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.green-circle{
	position: relative;
	padding-left: 20px;
}
.green-circle::before{
	position: absolute;
	content: ' ';
	width: 10px;
	height: 10px;
	background-image: url(assets/img/status_active.png);
	background-repeat: no-repeat;
	left: 4px;
	top: 6px;
}
.orange-circle{
	font-weight: normal;
	position: relative;
	padding-left: 20px;
}
.orange-circle::before{
	position: absolute;
	content: ' ';
	width: 10px;
	height: 10px;
	background-image: url(assets/img/status_pending.png);
	background-repeat: no-repeat;
	left: 4px;
	top: 6px;
}
.table.custom-table>thead>tr>th:nth-child(2),.table.custom-table>tbody>tr>td:nth-child(2){
	left: 160px;
}
.table>tbody{
	vertical-align: middle;
}
.dataTables_filter{
	left: 15px;
}
#datatable_previous{
	background-image: url(assets/img/page-nav-left.png);
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	background-position: center center;
	margin-right: 10px;
}
#datatable_previous a,#datatable_next a{
	visibility: hidden;

}
#datatable_next{
	background-image: url(assets/img/page-nav-right.png);
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	background-position: center center;
	margin-left: 10px;
}
.pagination{
	position: absolute;
	right: 0;
	padding-left: 0;
}
.fixedHeaderTable th{
	position: sticky !important;
	top: 0;
	z-index: 1;
}

.inactive{
	display: none !important;
}
.formContainer{
	padding-left: 45px;
	padding-right: 30px;
}
.formInputSelect{
	width: 100%;
	background: #FAFAFA 0% 0% no-repeat padding-box;
	box-shadow: 0px 0.5px 1px #18243E33;
	border: 1px solid #8C919D;
	outline: none !important;
	color: #8C919D;
	padding: 6px;
	border-radius: 5px;
	font-size: 14px;
	    font-family: "Open_Sans", Tofu;
}
.formContainer .close{
	display: none;
}
.formTabs{
	border-bottom: 1px solid #D9D9D9;
	margin-bottom: 10px;
	float: left;
	width: 100%;
}
.formTabs .formTabItem{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	padding-top: 10px;
	padding-bottom: 10px;
	float: left;
	margin-right: 30px;
	cursor: pointer;
	margin-left:10px;
}
.formTabs .formTabItem.active,.formTabs .formTabItem:hover{
	color: #2B384A;
	font-weight: normal;
	border-bottom: 2px solid #0077C8;
}
.formBlockTitle{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #2B384A;
	border-bottom: 2px solid rgb(144 150 164 / 50%);
	float: left;
	width: 100%;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.formContainer .formItemLabel{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #838697;
}
.formContainer .formItem input{
	border: 1px solid #D9D9D9;
	border-radius: 4px;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #2B384A;
}
.multiselect-dropdown .dropdown-btn{
	padding: 4px !important;
	border: 1px solid #D9D9D9 !important;
	border-radius: 4px !important;
	font-family: 'Open_Sans' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 22px !important;
	color: #2B384A !important;
	background-color: #FFF;
}
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before{
	border: 0 !important;
	background-image: url(assets/img/select-drop-arrow.png);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
	display: inline-block;
	height: 20px;
	top: 0 !important;

}
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret{
	transform: none !important;
}
.dropdown-list{
	border: 1px solid #D9D9D9 !important;
	border-radius: 4px !important;
	box-shadow: none;
}
.custom-dropdown-select{
	border: 1px solid #D9D9D9;
	border-radius: 4px;
	height: 44px;
	padding: 0 12px;
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 44px;
	color: #2B384A;
	float: left;
	width: 100%;
	position: relative;
	margin-top: 23px;
	background-color: #FFF;
}
.custom-dropdown-label::after{
	background-image: url(assets/img/select-drop-arrow.png);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
	display: inline-block;
	height: 33px;
	content: '';
	position: absolute;
	right: 5px;
	top:5px;
}
.custom-dropdown-list{
	position: absolute;
	top: 44px;
	left: 0;
	border: 1px solid #D9D9D9;
	border-radius: 4px;
	width: 100%;
	max-height: 120px;
	overflow: auto;
	background-color: #FFF;
	display: none;
}
.custom-dropdown-list.open{
	display: block;
}
.custom-dropdown-option{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #838597;
	padding: 5px;
	margin-bottom: 2px;
	border-bottom: 1px solid #D9D9D9;
}
.custom-dropdown-option:last-child{
	border: 0;
	margin-bottom: 0;
}
.custom-dropdown-option.selected{
	    font-family: "Open_Sans", Tofu;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 30px;
	color: #384555;
	position: relative;
}
.custom-dropdown-option.selected::after{
	background-image: url(assets/img/select-option-active.png);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
	display: inline-block;
	height: 30px;
	content: '';
	position: absolute;
	right: 5px;
	top:5px;
}
.dropdownMargin{
	margin-top: 23px;
	/*margin-right: 20px;*/

	background-color: transparent;
	color: #2B384A;
}
.form-control{
	padding: 4px 10px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,.p-dropdown-panel .p-dropdown-header{
	padding: 4px 10px;
}
 .p-dropdown{
	min-width: 160px;
	max-width: 100%;
	height: 32px;
	font-family: "Open_Sans" !important;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0.4px;
	text-align: left;
	border: 1px solid #D9D9D9;
	border-radius: 3px  !important;
	width: 100%;
}
.p-multiSelect{
	min-width: 160px;
	max-width: 100%;
	height: 32px;
	font-family: "Open_Sans" !important;
	font-size: 14px;
	font-weight: normal;
	line-height: 11px;
	letter-spacing: 0.4px;
	text-align: left;
	border: 1px solid #D9D9D9;
	border-radius: 6px;
	width: 100%;
	padding: 4px 10px;

}
.p-inputtext{
	font-size: 14px !important;
	padding: 3px 10px !important;
}
.p-dropdown-filter{
	padding: 2px 0.5rem !important;
}
.uploadWrap{
	position: relative;
    top: -22px;
}
.p-component{
	font-family: "Open_Sans" !important;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0.4px;
	text-align: left;

}

/*   dropdown error msg for pages only

   .p-inputwrapper{
	width: 100%;
    display: block;
}
change this class for model drop down issues
   */
.drop-error{
	width: 100%;
    display: block;
}
.p-component, .p-component *{
	font-size: 14px;
}
.table thead th{
	background: #8C919D;
    color: #FFF;
}
table.table td, table.table th {
    border-color: rgb(24 44 79 / 41%);
}
.add-btn{
	height:30px;
	border-radius: 0px;
	text-align: center;
}

table.dataTable{
	max-width: 100% !important;
}
.modal-title{
	font-size: 16px;
}
.inactive{
	display: none !important;
}
.formContainer{
	padding-left: 20px;
	padding-right: 20px;
}
.totalRecords{
	font-size: 14px;
}

header-bg-color{
	background-color:#8C919D;
	color:white;
}
.clear-search{
	border-radius: 10px;
	border:1px lightgrey solid;
	background-color: white;
	height:30px;
	cursor: pointer;
	position: absolute;
	right: -6px;
}

/******** Grid changes ************/
/*@font-face {*/
	/*font-family: 'Open_Sans';	font-weight: normal;*/
	/*font-style: normal;*/
	/*!*src:  url('/assets/fonts/Roboto/Roboto-Regular.ttf');*!*/
/*}*/
:root {
	--bs-blue: #559bfb;
	--bs-indigo: #2c3782;
	--bs-purple: #816bff;
	--bs-pink: #ff63a5;
	--bs-red: #e85347;
	--bs-orange: #ffa353;
	--bs-yellow: #f4bd0e;
	--bs-green: #1ee0ac;
	--bs-teal: #20c997;
	--bs-cyan: #09c2de;
	--bs-white: #fff;
	--bs-gray: #8091a7;
	--bs-gray-dark: #2b3748;
	--bs-gray-100: #ebeef2;
	--bs-gray-200: #e5e9f2;
	--bs-gray-300: #dbdfea;
	--bs-gray-400: #b7c2d0;
	--bs-gray-500: #8091a7;
	--bs-gray-600: #3c4d62;
	--bs-gray-700: #344357;
	--bs-gray-800: #2b3748;
	--bs-gray-900: #1f2b3a;
	--bs-primary: #6576ff;
	--bs-secondary: #364a63;
	--bs-success: #1ee0ac;
	--bs-info: #09c2de;
	--bs-warning: #f4bd0e;
	--bs-danger: #e85347;
	--bs-light: #e5e9f2;
	--bs-dark: #1f2b3a;
	--bs-gray: #8091a7;
	--bs-lighter: #f5f6fa;
	--bs-primary-rgb: 101, 118, 255;
	--bs-secondary-rgb: 54, 74, 99;
	--bs-success-rgb: 30, 224, 172;
	--bs-info-rgb: 9, 194, 222;
	--bs-warning-rgb: 244, 189, 14;
	--bs-danger-rgb: 232, 83, 71;
	--bs-light-rgb: 229, 233, 242;
	--bs-dark-rgb: 31, 43, 58;
	--bs-gray-rgb: 128, 145, 167;
	--bs-lighter-rgb: 245, 246, 250;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-color-rgb: 82, 100, 132;
	--bs-body-bg-rgb: 255, 255, 255;
	--bs-font-sans-serif: Roboto, sans-serif;
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-body-font-family: 'Open_Sans';
	--bs-body-font-size: 0.875rem;
	--bs-body-font-weight: normal;
	--bs-body-line-height: 1.65;
	--bs-body-color: #526484;
	--bs-body-bg: #fff;
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-lighter, .dual-listbox .dual-listbox__item:hover {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-lighter-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent !important;
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
	--bs-bg-opacity: 1;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.bg-blue-dim {
	background-color: #e9f2fe !important;
}

.bg-blue-dim {
	background-color: #e9f2fe !important;
}

.text-blue-dim {
	color: #e9f2fe !important;
}

.bg-azure-dim {
	background-color: #e1edfe !important;
}

.text-azure-dim {
	color: #e1edfe !important;
}

.bg-indigo-dim {
	background-color: #e4e5ef !important;
}

.text-indigo-dim {
	color: #e4e5ef !important;
}

.bg-purple-dim {
	background-color: #efecff !important;
}

.text-purple-dim {
	color: #efecff !important;
}

.bg-pink-dim {
	background-color: #ffebf3 !important;
}

.text-pink-dim {
	color: #ffebf3 !important;
}

.bg-orange-dim {
	background-color: #fff3e9 !important;
}

.text-orange-dim {
	color: #fff3e9 !important;
}

.bg-teal-dim {
	background-color: #e2f8f1 !important;
}

.text-teal-dim {
	color: #e2f8f1 !important;
}

.bg-primary-dim, .dual-listbox .dual-listbox__item:active, .dual-listbox .dual-listbox__item.dual-listbox__item--selected {
	background-color: #ebedff !important;
}

.text-primary-dim {
	color: #ebedff !important;
}

.bg-success-dim {
	background-color: #e2fbf4 !important;
}

.text-success-dim {
	color: #e2fbf4 !important;
}

.bg-info-dim {
	background-color: #dff7fb !important;
}

.text-info-dim {
	color: #dff7fb !important;
}

.bg-warning-dim {
	background-color: #fef6e0 !important;
}

.text-warning-dim {
	color: #fef6e0 !important;
}

.bg-danger-dim {
	background-color: #fce9e7 !important;
}

.text-danger-dim {
	color: #fce9e7 !important;
}

.bg-secondary-dim {
	background-color: #e5ecf5 !important;
}

.text-secondary-dim {
	color: #e5ecf5 !important;
}

.bg-dark-dim {
	background-color: #d9e1ef !important;
}

.text-dark-dim {
	color: #d9e1ef !important;
}

.bg-gray-dim {
	background-color: #edf2f9 !important;
}

.text-gray-dim {
	color: #edf2f9 !important;
}

.user-avatar[class*=-blue-dim] {
	color: #559bfb;
}

.user-avatar[class*=-azure-dim] {
	color: #1676fb;
}

.user-avatar[class*=-indigo-dim] {
	color: #2c3782;
}

.user-avatar[class*=-purple-dim] {
	color: #816bff;
}

.user-avatar[class*=-pink-dim] {
	color: #ff63a5;
}

.user-avatar[class*=-orange-dim] {
	color: #ffa353;
}

.user-avatar[class*=-teal-dim] {
	color: #20c997;
}

.user-avatar[class*=-primary-dim] {
	color: #6576ff;
}

.user-avatar[class*=-secondary-dim] {
	color: #364a63;
}

.user-avatar[class*=-success-dim] {
	color: #1ee0ac;
}

.user-avatar[class*=-info-dim] {
	color: #09c2de;
}

.user-avatar[class*=-warning-dim] {
	color: #f4bd0e;
}

.user-avatar[class*=-danger-dim] {
	color: #e85347;
}

.user-avatar[class*=-dark-dim] {
	color: #1f2b3a;
}

.user-avatar[class*=-gray-dim] {
	color: #8091a7;
}

.user-avatar[class*=-lighter] {
	color: #8094ae;
}

.user-avatar[class*=-light] {
	color: #526484;
}
.custom-table-basic{
	color: #8094ae;
	font-family: 'Open_Sans';
}
.custom-table-basic tr{
	transition: background-color 0.3s, box-shadow 0.3s;
	border-bottom: 1px solid #dbdfea;
	background-color: #FFF !important;
}
table.dataTable td, table.dataTable th{
	background-color: #FFF !important;
	color: #46576c !important;
	font-weight: normal;
	--bs-table-accent-bg:#FFF;
	--bs-bg-opacity: 1;
}
.table thead th{
	background: #FFF;
	text-transform: capitalize;

}
.table.custom-table>tbody>tr>td:first-child,.table.custom-table>tbody>tr>td:nth-child(2),.table.custom-table>tbody>tr>td:last-child,.table.custom-table>tbody>tr>td:nth-last-child(2),
.table.custom-table>thead>tr>th:first-child,.table.custom-table>thead>tr>th:nth-child(2),.table.custom-table>thead>tr>th:nth-last-child(2),.table.custom-table>thead>tr>th:last-child{
	position: static !important;
}

.table thead th{
	background-color: #F7F7F7;
	color: black;
	font-weight: bold;
	text-align: center;
	border:0.3px solid rgba(0, 0, 0, 0.12) !important;
}
.table tbody td{
	border:0.3px solid rgba(0, 0, 0, 0.18) !important;
	text-align: start;
}
table.table td, table.table th{
	text-wrap: nowrap;
}
table.dataTable{
	padding-top: 0px;
}
.custom-table-wrapper #datatable_wrapper .row:nth-child(2){
	max-height: 100%;
}
.table .dropdown-menu{
	position: absolute !important;
}
.table .dropdown-menu.dropdownaction{
	transform: translate(-24px, -37px) !important;
}
.content.container-fluid{
	min-height: auto;
}
.green-circle{
	color: #1ee0ac;
}
.rowSelect::before,.rowSelect::after{
	display: none !important;
}
.user-avatar, [class^=user-avatar]:not([class*=-group]) {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: #798bff;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: initial;
	flex-shrink: 0;
	position: relative;
}
.user-avatar-xs, .user-avatar.xs {
	height: 26px;
	width: 26px;
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
}
.user-card {
	display: flex;
	align-items: center;
}
.user-card .user-name{
	margin-left: 5px;
}
.grid-table{
	border: 1px solid ;
	border-color :rgb(24 44 79 / 20%);
	line-height: 10px ;
	padding: 10px;
	padding-bottom: 50px;
	background: #FFF;
	border-radius: 4px;
}
.pagination{
	padding-top: 20px;
}
.table-responsive{
	overflow-x: auto;
}


.p-dropdown-empty-message,.p-dropdown .p-dropdown-label.p-placeholder,.p-dropdown-item,.p-inputtext{
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open_Sans' !important;
}
.p-inputnumber-input{
	min-width: 242px;
	max-width: 100%;
}
.status-success{
	color: #238056;
	font-weight: bold;
}
.status-fail{
	color: red;
	font-weight: bold;
}
.p-tabview .p-tabview-panels{
	padding:0px;
	font-size: 14px;
}
.p-multiselect{
	line-height: 12px;
	width:247px;

}
.p-tooltip .p-tooltip-text {
	background-color: #FFFFFF !important;
	color: black !important;
	text-align: start;
	/*min-width:250px;*/
	/*max-width:550px;*/
	/*min-width:10px;*/
	/*min-height:10px;*/

}
/*div .p-tooltip .p-tooltip-text {*/
	/*background-color: grey !important;*/
	/*color: green;*/
/*}*/
/*.p-tooltip-arrow{*/
	/*background-color: dodgerblue !important;*/
/*}*/
div.dataTables_wrapper div.dataTables_filter label{
	display:none;
}
.header {
	height:40px;
	width:100%;
}
.table>tbody>tr>td:last-child {
	position: sticky;
	right: 0px;
}

.table>thead>tr>th:last-child {
	position: sticky;
	right: 0px;
}
@import './assets/css/sidebar.scss';
@import './assets/css/header.scss';
@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
		max-width: 1860px;
	}
  .search-form{
    width:60%;
  }
  .login-header {
    /* float: right; */
    padding: 5%;
    width:100%;
    /* width: fit-content; */
}
}
:host ::ng-deep .p-chips .p-chips-multiple-container .p-chips-input-token input{
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open_Sans';
}

/******** UI changes ********/
#sidebar .userInfo{
	display: none;
}
#sidebar {
	display: none;
	left:-210px;
  /* color: #fff;
  background-color: #3e75b3; */
	/* position: relative !important; */
	-webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    transition: all 2s ease;
    overflow:scroll;
	scrollbar-width: 2px;
     /* scrollbar-color: red orange;
  scrollbar-width: thin; */
}
#sidebar::-webkit-scrollbar {
	width: 4px; /* Adjust the width as needed */
}
#sidebar::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Change the color as needed */
}

/* Thumb (the draggable part of the scrollbar) */
#sidebar::-webkit-scrollbar-thumb {
	background-color: #888; /* Change the color as needed */
}
#sidebar.active{
	left:0;
	display: block;

}
#sidebar.fixed{
	position: fixed !important;
}
.page-wrapper{
	margin-left: 0;
}
.headerRight{width: 100%;}
.header-left{display: none;}
.sidebar-menu>ul>li{
	list-style: none;
}
.user-menu {
    width: calc(100% - 100px);
}
.headerRight .site-logo-img{
	margin-left: 20px;
    position: relative;
    top: -6px;
}
.headerRight .top-nav-search{
    position: relative;
    top: -3px;
}
.headerRight  .user-menu.nav>li>a:hover,.headerRight  .user-menu.nav>li>a:focus{
	background: transparent;
}
.headerRight  .nav-item.dropdown{
	top:-20px;
}
.headerNavFloatRightItems{
	position: relative;
}
.loginTime.headerNavFloatRightItems{
	top: -1px;
}
.loginImg.headerNavFloatRightItems{
	top:0
}

.inv-logo{
	min-width: 70px;
	max-width: 100px;
	min-height: 50px;
	max-height: 60px;
	border-radius: 15px;
}
.payslip-ear-dec-grid{
	/* margin-bottom:-54px; */
}
.tax-comp{
	margin-bottom:-30px;
}
.page-wrapper .content{
	padding:10px !important;
}
.form-control{
	height:31px;
	font-size: 14px;
}
.top-filter-drop-down{
	width:10%;
}
.btn-white{
	height:30px;
}
.span-number{
	color:#0766FB !important;
	/* text-decoration: underline; */
}
.table>:not(caption)>*>* {
	/*padding: 5px !important;*/
	/*color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)));*/
	/*background-color: var(--bs-table-bg);*/
	/*border-bottom-width: var(--bs-border-width);*/
	/*box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)));*/
}
.modal-title{
	font-size: 16px;
}
.page-title{
	font-size: 16px;
}
.chckbox{
   margin-top: 9%;
    margin-right: -28px;
}
.smtbutton{
   margin-top:10px;
}
.eyePay{
	margin-left: 20px;

}
.mar-top{
	margin-top: 22px;
}
/* For Desktop View */
@media screen and (min-width: 1024px) {
  .login-header {
    /* float: right; */
    padding: 10%;
    width: -moz-fit-content;
    /* width: fit-content; */
  }
}

/* For Tablet View */
@media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
    and (orientation: portrait) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
    and (orientation: landscape) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}


/* For Mobile Phones Portrait or Landscape View */
@media screen
    and (max-device-width: 640px) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}


/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .login-header {
        /* float: right; */
        padding: 15%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px)
    and (device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px)
    and (min-device-width: 375px)
    and (-webkit-min-device-pixel-ratio: 3) {
      .login-header {
        /* float: right; */
        padding: 10%;
        width: -moz-fit-content;
        /* width: fit-content; */
      }
}
.p-tabview .p-tabview-panels{
	padding:0px !important;
}

.active-icon{
	color: green;
	font-size: 50px;
  }
  .inactive-icon{
	color: red;
	font-size: 50px;
  }
.input-icons i {
	position: absolute;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.icon {

	display: inline-flex;
	padding: 8px;
	margin-left:-13% ;
	min-width: 50px;
	text-align: center;
}
.inlines{
	display: inline-flex;
}
.comp-name{
	font-size:16px;
	color:#3e75b3;
	font-weight: bold;
}
.comp-address{
	font-size:16px;
	color:#3e75b3;
}
.link-info{
	cursor: pointer;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
	color :black !important;
}
ol,ul{
	padding-left:0rem;
}
/*a{*/
	/*color :black !important;*/
/*}*/
.p-scrollpanel .p-scrollpanel-bar{
	background-color: #dadada;
}
.legend-title{
	color:black;
	font-size: 15px;
	font-weight: bold;
}
.width-search{
	width:150px;
}
.clear-width{
	width:100px;
}
.p-datepicker-calendar>thead>tr{
text-align: center;
}
.p-datepicker{
	border-bottom: 0px;
}
.title-label
{
	display:inline-flex;
	width:50%;
	font-weight: bold;
	color:black;
}
.title-value
{
	display:inline-flex;
	width:50%;
	font-size:13px;
	color: rgba(128, 128, 128, 0.82);

}
.reason-value
{
	display:inline-flex;
	width:70%;
	font-size:13px;
	color: rgba(128, 128, 128, 0.82);
	min-height:70px;

}
.reason-label{
	display:inline-flex;
	width:30%;
	font-weight: bold;
	color:black;
}
.margin-tabs{

	margin-left:10px;
}
.emp-scroll{
    height: 250px;
    width: 250px;
    overflow: auto;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1% !important;
}
.card{
    color:#495057;;
}
.fc .fc-toolbar>*>:first-child {
    margin-left: 0;
    font-size: 16px;
}
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #3B82F6 !important;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #ced4da !important;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
}
.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
    line-height: 1.5;
	min-width: 47px;
	text-align: center;
}

/*.fc .fc-daygrid-day-frame{*/
	/*min-height:0vh !important;*/
/*}*/
.fc .fc-daygrid-day-top{
	height:20px !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
	/*margin-right: 5%;*/
	margin-top:5px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
	/*margin-left:5%;*/
}

.fc .fc-toolbar>*>:first-child
{
	text-transform: capitalize;
}
.leave-pending{
	color:black;
	background-color: #fff;
}
.leave-pending:hover,.leave-pending:active,.leave-pending:focus,.leave-pending:focus:checked{
	color:black !important;
	background-color: #fff;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
	color:black !important;
	background-color: #fff;
}
a{
	color:black;
}
.link{
	color:dodgerblue;
	cursor: pointer;
	border-bottom: dodgerblue 1px solid;
}
.leave-ap-pending,.leave-ap-pending:hover{
	background-color: #fff;
	color:black;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 #a6aef5 !important;
	border:0px;

}
.leave-ap-cancel,.leave-ap-cancel:hover{
	background-color: #fff;
	color:black;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(255, 178, 106, 0.61) !important;
	border:0px;
}
.leave-ap-reject,.leave-ap-reject:hover{
	background-color: #fff;
	color:black;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(255, 140, 120, 0.61) !important;
	border:0px;

}
.leave-ap-approve,.leave-ap-approve:hover{
	background-color: #fff;
	color:black;
	box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(116, 255, 106, 0.61) !important;
	border:0px;

}
.dropdown-menu.show{
	transform: translate3d(0px, -50px, 0px) !important;
}
/*button.p-column-filter-menu-button{*/
	/*right: -70% !important;*/
	/*top: -49px !important;*/
/*}*/
p-columnFilter{
	/*float: right !important;*/
	/*!*top: 0px !important;*!*/
	/*!*right:-4px !important;*!*/
	position: relative !important;
	top:2px !important;
	/*left:-25px !important;*/
	/*margin-right:3px; */
	/*display:none;*/
}
/*.col-header{*/
	/*margin-left:40px;*/
/*}*/
filtericon svg{
	width:10px !important;
}
.button-end{
  float: right;

}
.fa-sign-out {
  font-size: 16px;
  margin-top: 9px;
}
.box-shadow{
	box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);

}

.fc-event {
	margin-bottom: 5px; /* Adjust as needed */
}

/* Adjust the height of time slots */
.fc-time-grid .fc-slats td {
	height: 20px; /* Adjust as needed */
}
.fc .fc-daygrid-event-harness-abs{
	margin-right:-12vh !important; ;
}
.chart-container{
	font-family: "Open_Sans", Tofu !important;
}
.fc-scrollgrid  .fc-scrollgrid-liquid{
	height:400px !important;
	overflow: hidden !important;
}
/*.ff{*/
	/*!*position: relative !important;*!*/
	/*right: -8vh !important;*/
	/*top: -3vh !important;*/
/*}*/


/*@media (min-height:600px) {*/
	/*!*.fc .fc-view-harness {*!*/
		/*!*height: 140vh !important;*!*/
	/*!*}*!*/
	/*full-calendar {*/
		/*height:100vh !important*/
	/*}*/
	/*.no-scroll{*/

		/*height:110vh !important*/
	/*}*/
/*}*/
/*@media (min-height:900px) {*/
	/*full-calendar {*/
		/*height:100vh !important*/
	/*}*/
	/*.no-scroll{*/

		/*height:110vh !important*/
	/*}*/
/*}*/
/*fc-day fc-day-sun fc-day-past fc-daygrid-day {*/
    /*height: 30px !important;*/
/*}*/
/*.fc-day {*/
	/*!* Common styles for all calendar days *!*/
	/*height: 30px !important;*/
/*}*/


/*.fc-day-sun {*/
	/*!* Styles specifically for Sundays *!*/
	/*height: 30px !important;*/

/*}*/

/*.fc-day-past {*/
	/*!* Styles for past dates *!*/
	/*height: 30px !important;*/

/*}*/

/*.fc-daygrid-day {*/
	/*height: 30px !important;*/

	/*!* Styles for the day grid cells *!*/
/*}*/
/* Targeting a specific td inside fc-scrollgrid-sync-table */
/*.fc-scrollgrid-sync-table tbody td {*/
	/*height: 100px; !* Set your desired height here *!*/
/*}*/
/*.fc-scrollgrid-sync-table tbody td {*/
	/*height: 85px !important;*/
/*}*/

/*full-calendar {*/
	/*!*width: 100%; !* Adjust width as needed *!*!*/
	/*!*height: 100vh; !* Adjust height as needed *!*!*/
	/*!*!* Other styles *!*!*/
	/*!*float: right;*!*/
	/*overflow: auto !important; !* Override any other styles *!*/
	/*max-width: 100%; !* Set a maximum width if needed *!*/
	/*min-height: 100vh; !* Set a maximum height if needed *!*/
	/*max-height: 150vh; !* Set a maximum height if needed *!*/
	/*!* Other styles *!*/
/*}*/
/*.card.no-scroll {*/
	/*overflow: hidden !important; !* Override any other styles *!*/
	/*max-height: 100%; !* Set a maximum height if needed *!*/
	/*max-width: 100%; !* Set a maximum width if needed *!*/
	/*!* Other styles *!*/
/*}*/



/*[role="gridcell"] {*/
	/*!* Your styles here *!*/
	/*height:30px !important;*/
/*}*/
/*full-calendar {*/
	/*!* Your styles here *!*/
/*height:100vh;*/
/*}*/
.p-fieldset-legend>a, .p-fieldset-legend>span {
  display: flex;
  align-items: center;
  justify-content: unset;
}
.fc .fc-scroller-liquid-absolute{

	/* color: #fff;
    background-color: #3e75b3; */
	/*position: relative !important;*/
	-webkit-transition: all 2s ease;
	-moz-transition: all 2s ease;
	transition: all 2s ease;
	overflow:scroll;
	scrollbar-width: 2px;
	/* scrollbar-color: red orange;
 scrollbar-width: thin; */
}
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar {
	width: 4px; /* Adjust the width as needed */
}
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Change the color as needed */
}

/* Thumb (the draggable part of the scrollbar) */
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
	background-color: #888; /* Change the color as needed */
}
.btn-dec{
  width: 93%;
}
.setting-align{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem  !important;
}

.img_fit_align{
  object-fit: contain;
}
.svg-inline--fa{
  height:2em;
}
.cursor{
  cursor:pointer;
}
.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 0.4rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-button {
    color: #ffffff;
    background: #2196F3;
    border: 1px solid #2196F3;
    padding: 0.3rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
	margin: 3px;

}
.p-fileupload .p-fileupload-content {
	padding: 0px;
}
.p-fileupload .p-fileupload-row > div {
    padding: 10px;
}
.p-fileupload-row>div {
    flex: 1 1 auto;
     width:auto;
}
.headers-drop-down{
    display: flex;
    gap: 10px;
	padding:10px 30px;
}
.header-drop-sub{
    flex:2.5;
}
.p-calendar {
    position: relative;
    display: inline-flex;
    width: 100%;
	height: 31px;

}
.work-status-event {
	background-color: #edd3d1;;
	height: 25px;
	color: black;
	font-size: 14px;
	font-family: "Open_Sans" !important;
	position:relative;
	top:50%;
  padding: 4px;
}
.work-shift-event {
	background-color: rgb(247, 235, 250);
	height: 25px;
	color: black;
	font-size: 14px;
	font-family: "Open_Sans" !important;
	position:relative;
	top:50%;
  padding: 4px;
}
.p-inputmask{
	position: relative;
	display: inline-flex;
	width: 100%;
	height: 31px;
}
.p-fileupload.p-fileupload-advanced.p-component.ng-star-inserted {
  display: flex;
  /* width: 50px; */
}
.p-fileupload .p-fileupload-content {
  padding: 0px;
  /* padding-left: 100px; */
  width: 250px;
}

.p-fileupload-row.ng-star-inserted {
  max-height: 100px;
  width: 100%;
  overflow: hidden;
display: block;
}
.p-fileupload .p-fileupload-row > div {
  padding-bottom: 2px;
  padding-top: 2px;
}
/* Custom styles for the file upload component */
.ui-fileupload-row .ui-fileupload-filename {
  display: inline-block;
  /* padding: 4px 10px; */
}

/* Hide the default file size and cancel icon */
.ui-fileupload-row .ui-fileupload-filesize,
.ui-fileupload-row .ui-fileupload-action {
  display: none;
}
.title-label-col-8 {
  display: inline-flex;
  width: 30%;
  font-weight: bold;
  color: black;
}
.title-value-col-8 {
	display:inline-flex;
	width:30%;
	font-size:13px;
	color: rgba(128, 128, 128, 0.82);

}
.active-button {
    background-color: green !important;  /* Important to override existing styles */
    border-color: green !important;
}

/* Default button styles if needed */
.default-button {
    background-color: #2196F3;  /* Default background color */
    border-color: #2196F3;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 4em;
  position: relative;
}
.link-summary{
    color: rgb(121, 180, 248);
    /* border-bottom: 2px solid rgb(121, 180, 248); */
    cursor: pointer;
}
.color{

    background-color: rgb(253 253 202) !important;
  }
